// import ReportsRepository from '@/libs/axios'
import ReportsRepository from '@/libs/customAxios/reportsAxios'

const monthlySalesReportResource = '/admin/report/monthly-sales-report'
const merchantStatusCountReport = '/admin/report/merchant-status-count-report'
const branchStatusCountReport = '/admin/report/warehouse/branch-status-count-report'
const agencyStatusCountReport = '/admin/report/warehouse/branch-status-count-report'
const warehosueStatusCountReport = '/admin/report/warehouse-status-count-report'
const assignOrderDeliveryRiderReport = '/admin/report/assigned-orders-to-delivery-rider-report'
const assignOrderPickupRiderReport = '/admin/report/assigned-orders-to-pickup-rider-report'
const assignOrderRescheduledReport = '/admin/report/assigned-orders-rescheduled-report'
const returnOrdersMerchantReport = '/admin/report/return-order-merchant-report'
const returnOrderWarehouseReport = '/admin/report/orders-assigned-to-warehouse-report'
const returnOrderOriginWarehouseReport = '/admin/report/orders-returned-to-origin-warehouse-report'
const receivedOrderOriginWarehouseReport = '/admin/report/received-orders-to-origin-warehouse-report'
const returnOrderOriginWarehouseReportId = '/admin/report/orders-returned-to-origin-warehouse-id-report'
const branchExpensesReport = '/admin/report/branch-expenses-report'
const branchProgressReport = '/admin/report/branch-progress-report'
const toBeInvoicedReport = '/admin/report/to-be-invoiced-report'
const monthlySalesReportOverviewResource = '/admin/report/monthly-sales-report-overview'

const defaultPagination = 10
export default {
  getmonthlySalesReport(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${monthlySalesReportResource}?paginate=${pagination}&page=${pageNumber}&${filterQuery}&sort=-id`)
  },
  getmonthlySalesReportNoPagination(filterQuery) {
    return ReportsRepository.get(`${monthlySalesReportResource}?${filterQuery}&sort=-id&noPagination`)
  },
  getbranchStatusCountReport() {
    return ReportsRepository.get(`${branchStatusCountReport}?sort=-id`)
  },
  getagencyStatusCountReport() {
    return ReportsRepository.get(`${agencyStatusCountReport}?sort=-id`)
  },
  getWarehouseStatusCountReport(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${warehosueStatusCountReport}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getWarehouseStatusCountReportNoPagination(filterQuery) {
    return ReportsRepository.get(`${warehosueStatusCountReport}?${filterQuery}&noPagination`)
  },
  getFilteredWarehouseStatusCountReport(id) {
    return ReportsRepository.get(`${warehosueStatusCountReport}?filter[current_warehouse]=${id},`)
  },
  getmerchantStatusCountReport(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${merchantStatusCountReport}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getmerchantStatusCountReportNoPagination(filterQuery) {
    return ReportsRepository.get(`${merchantStatusCountReport}?${filterQuery}&noPagination`)
  },
  getAssignOrderDeliveryRiderReport(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${assignOrderDeliveryRiderReport}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAssignOrderDeliveryRiderReportNoPagiantion(filterQuery) {
    return ReportsRepository.get(`${assignOrderDeliveryRiderReport}?${filterQuery}&sort=-id&noPagination`)
  },
  getAssignOrderPickupRiderReport(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${assignOrderPickupRiderReport}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAssignOrderPickupRiderReportNoPagiantion(filterQuery) {
    return ReportsRepository.get(`${assignOrderPickupRiderReport}?${filterQuery}&sort=-id&noPagination`)
  },
  getAssignOrderRescheduledReport(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${assignOrderRescheduledReport}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAssignOrderRescheduledReportNoPagiantion(filterQuery) {
    return ReportsRepository.get(`${assignOrderRescheduledReport}?${filterQuery}&sort=-id&noPagination`)
  },
  getreturnedOrderMerchantReport(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${returnOrdersMerchantReport}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getreturnedOrderMerchantReportNoPagiantion(filterQuery) {
    return ReportsRepository.get(`${returnOrdersMerchantReport}?${filterQuery}&sort=-id&noPagination`)
  },
  getreturnOrderWarehouseReport(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${returnOrderWarehouseReport}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getreturnOrderWarehouseReportNoPagiantion(filterQuery) {
    return ReportsRepository.get(`${returnOrderWarehouseReport}?${filterQuery}&sort=-id&noPagination`)
  },
  getreturnOrderOriginWarehouseReport(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${returnOrderOriginWarehouseReport}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getreturnOrderOriginWarehouseReportNoPagiantion(filterQuery) {
    return ReportsRepository.get(`${returnOrderOriginWarehouseReport}?${filterQuery}&sort=-id&noPagination`)
  },
  getreceivedOrderOriginWarehouseReport(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${receivedOrderOriginWarehouseReport}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getreceivedOrderOriginWarehouseReportNoPagiantion(filterQuery) {
    return ReportsRepository.get(`${receivedOrderOriginWarehouseReport}?${filterQuery}&sort=-id&noPagination`)
  },
  getreturnOrderOriginWarehouseReportId(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${returnOrderOriginWarehouseReportId}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getreturnOrderOriginWarehouseReportIdNoPagiantion(filterQuery) {
    return ReportsRepository.get(`${returnOrderOriginWarehouseReportId}?${filterQuery}&sort=-id&noPagination`)
  },
  getBranchExpensesReport(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${branchExpensesReport}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getBranchExpensesReportNoPagiantion(filterQuery) {
    return ReportsRepository.get(`${branchExpensesReport}?${filterQuery}&sort=-id&noPagination`)
  },
  getBranchProgressReport(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${branchProgressReport}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getBranchProgressReportNoPagiantion(filterQuery) {
    return ReportsRepository.get(`${branchProgressReport}?${filterQuery}&sort=-id&noPagination`)
  },
  getToBeInvoicedReport(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ReportsRepository.get(`${toBeInvoicedReport}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getToBeInvoicedReportNoPagiantion(filterQuery) {
    return ReportsRepository.get(`${toBeInvoicedReport}?${filterQuery}&sort=-id&noPagination`)
  },
  getmonthlySalesReportOverview() {
    return ReportsRepository.get(`${monthlySalesReportOverviewResource}`)
  },
}
