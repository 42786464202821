import Repository from '@/libs/axios'

const notificationResource = '/admin/notifications'
const reminderNotificationResource = '/admin/notifications/order-reminders-notifications'
const financeNotificationResource = '/admin/notifications/finance-notifications'
const otherNotificationResource = '/admin/notifications/other-notifications'
const merchantNotificationResource = '/admin/notifications/merchant'
const pickupRequestResource = '/admin/notifications/pickup-request-notifications'
const redeliveryNotificationResource = '/admin/notifications/redelivery-notifications'

const defaultPagination = 10

export default {
  createMerchantNotification(payload) {
    return Repository.post(`${merchantNotificationResource}`, payload)
  },
  getMerchantNotifications(pageNumber, pagination = defaultPagination) {
    return Repository.get(`${merchantNotificationResource}?paginate=${pagination}&page=${pageNumber}&sort=-id`)
  },
  createReminderNotification() {
    return Repository.post(`${reminderNotificationResource}`)
  },
  getReminderNotifications() {
    return Repository.get(`${reminderNotificationResource}`)
  },
  createFinanceNotification() {
    return Repository.post(`${financeNotificationResource}`)
  },
  getFinanceNotifications() {
    return Repository.get(`${financeNotificationResource}`)
  },
  getOtherNotifications() {
    return Repository.get(`${otherNotificationResource}`)
  },
  getRedeliveryNotifications() {
    return Repository.get(`${redeliveryNotificationResource}`)
  },
  getPickupRequestNotifications() {
    return Repository.get(`${pickupRequestResource}`)
  },
  getAllNotifications(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return Repository.get(`${notificationResource}?${filterQuery}&page=${pageNumber}&paginate=${pagination}`)
  },
  getAllNotificationsNoPagination(filterQuery) {
    return Repository.get(`${notificationResource}?${filterQuery}&noPagination`)
  },
  markAsReadAll() {
    return Repository.put(`${notificationResource}/mark-as-read`)
  },
  markAsReadSingle(id) {
    return Repository.put(`${notificationResource}/mark-as-read/${id}`)
  },
  deleteNotification(payload) {
    return Repository.delete(`${notificationResource}`, {
      params: payload,
    })
  },
}
