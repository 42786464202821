import Repository from '@/libs/axios'

const merchantResource = '/admin/merchant'
const merchantSyncResource = '/admin/merchant-sync'
const merchantApprovalResource = 'admin/approvals/request'

const defaultPagination = 10

export default {
  getMerchantList(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return Repository.get(`${merchantResource}?paginate=${pagination}&page=${pageNumber}&${filterQuery}&sort=-id`)
  },
  getMerchantListNoPagination(filterQuery) {
    return Repository.get(`${merchantResource}?${filterQuery}&sort=-id&noPagination`)
  },
  getMerchantListForDropdown() {
    return Repository.get(`${merchantResource}/dropdown/list`)
  },
  getMerchantDetails(id) {
    return Repository.get(`${merchantResource}/${id}`)
  },
  createMerchant(payload) {
    return Repository.post(`${merchantResource}`, payload)
  },
  updateMerchant(id, payload) {
    return Repository.put(`${merchantResource}/${id}`, payload)
  },

  getMerchantUserList(id, businessId) {
    return Repository.get(`${merchantResource}/${id}/user?${businessId ? `filter[merchant_business_id]=${businessId}` : ''}`)
  },
  getMerchantUserDetails(id, adminId) {
    return Repository.get(`${merchantResource}/${id}/user/${adminId}`)
  },
  createMerchantUser(id, payload) {
    return Repository.post(`${merchantResource}/${id}/user`, payload)
  },
  updateMerchantUser(id, userId, payload) {
    return Repository.put(`${merchantResource}/${id}/user/${userId}`, payload)
  },
  // eslint-disable-next-line camelcase
  merchantUserResetPassword(merchant_id, user_id, payload) {
    // eslint-disable-next-line camelcase
    return Repository.put(`${merchantResource}/${merchant_id}/user/${user_id}/reset-password`, payload)
  },

  getMerchantBusinessList(id) {
    return Repository.get(`${merchantResource}/${id}/business`)
  },
  getMerchantBusinessListDropdown(merchnatId) {
    return Repository.get(`${merchantResource}/${merchnatId}/merchantbusinessdropdown`)
  },
  setBusinessAsDefault(merchantId, businessId) {
    return Repository.post(`${merchantResource}/${merchantId}/business/${businessId}/set-default`)
  },
  getMerchantBusinessDetails(id, businessId) {
    return Repository.get(`${merchantResource}/${id}/business/${businessId}`)
  },
  createMerchantBusiness(id, payload) {
    return Repository.post(`${merchantResource}/${id}/business`, payload)
  },
  updateMerchantBusiness(id, businessId, payload) {
    return Repository.put(`${merchantResource}/${id}/business/${businessId}`, payload)
  },

  getMerchantAddressList(pageNumber = 1, id, businessId, pagination = defaultPagination) {
    return Repository.get(`${merchantResource}/${id}/business/${businessId}/address?page=${pageNumber}&paginate=${pagination}`)
  },
  getMerchantAddressDetails(id, businessId, addressId) {
    return Repository.get(`${merchantResource}/${id}/business/${businessId}/address/${addressId}`)
  },
  createMerchantAddress(id, businessId, payload) {
    return Repository.post(`${merchantResource}/${id}/business/${businessId}/address`, payload)
  },
  createMerchantContact(id, businessId, addressId, payload) {
    return Repository.post(`${merchantResource}/${id}/business/${businessId}/address/${addressId}/contact`, payload)
  },
  updateMerchantAddress(id, businessId, addressId, payload) {
    return Repository.put(`${merchantResource}/${id}/business/${businessId}/address/${addressId}`, payload)
  },
  updateMerchantContact(id, businessId, addressId, contactId, payload) {
    return Repository.put(`${merchantResource}/${id}/business/${businessId}/address/${addressId}/contact/${contactId}`, payload)
  },
  getMerchantBusinessSettingsDetails(id) {
    return Repository.get(`${merchantResource}/${id}/setting`)
  },
  updateMerchantBusinessSettings(id, payload) {
    return Repository.put(`${merchantResource}/${id}/setting`, payload)
  },
  getbusinessAddresses(merchantId, businessId, concat) {
    return Repository.get(`${merchantResource}/${merchantId}/business/${businessId}/addresses/list?concat=${concat}`)
  },
  getThirdPartyDetails(merchantId) {
    return Repository.get(`${merchantSyncResource}/${merchantId}`)
  },
  generateClientSecret(merchantId) {
    return Repository.post(`${merchantSyncResource}/auth-generate/${merchantId}`)
  },
  getMerchantBusinessIntergrationDetailsById(businessId) {
    return Repository.get(`${merchantResource}/business/${businessId}/integrations`)
  },
  approveIntergrationRequest(businessId) {
    return Repository.put(`${merchantResource}/business/${businessId}/approve`)
  },
  getSalesPersonDetails(merchantId) {
    return Repository.get(`${merchantResource}/${merchantId}/sales-person`)
  },
  updateSalesPerson(merchantId, id, payload) {
    return Repository.post(`${merchantResource}/${merchantId}/sales-person/addOrEdit`, payload)
  },
  getRequestsList(pagination = defaultPagination, pageNumber = 1, filterQuery) {
    return Repository.get(`${merchantApprovalResource}?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getRequestDetails($id) {
    return Repository.get(`${merchantApprovalResource}/${$id}`)
  },
  approveRequestDetails(payload) {
    return Repository.post(`${merchantApprovalResource}/approve`, payload)
  },
  rejectRequestDetails(payload) {
    return Repository.post(`${merchantApprovalResource}/reject`, payload)
  },
}
