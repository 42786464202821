import Repository from '@/libs/axios'

const getAvailabletagsResource = '/admin/settings/status/notification-tags'
const statusNotificationResource = '/admin/settings/status/notification'
const reminderNotificationResource = '/admin/settings/reminder/notification'
const financeNotificationResource = '/admin/settings/finance-status/notification'
const otherNotificationResource = '/admin/settings/other/notification'

export default {
  getAvailabletagsResource() {
    return Repository.get(`${getAvailabletagsResource}`)
  },
  getStatusNotificationResource() {
    return Repository.get(`${statusNotificationResource}`)
  },
  updateStatusNotificationResource(payload) {
    return Repository.put(`${statusNotificationResource}`, payload)
  },
  getStatusNotificationResourceByID(id) {
    return Repository.get(`${statusNotificationResource}?id=${id}`)
  },
  getReminderNotificationResource() {
    return Repository.get(`${reminderNotificationResource}`)
  },
  updateReminderNotificationResource(payload) {
    return Repository.put(`${reminderNotificationResource}`, payload)
  },
  getfinanceNotificationResource() {
    return Repository.get(`${financeNotificationResource}`)
  },
  updateFinanceNotificationResource(payload) {
    return Repository.put(`${financeNotificationResource}`, payload)
  },
  getOtherNotificationResource() {
    return Repository.get(`${otherNotificationResource}`)
  },
  updateOtherNotificationResource(payload) {
    return Repository.put(`${otherNotificationResource}`, payload)
  },
}
