import Repository from '@/libs/axios'

const updateColor = '/admin/settings/theme'
const getColor = '/settings/theme-color-ui'

export default {
  setColor(payload) {
    return Repository.put(`${updateColor}`, payload)
  },
  getColor() {
    return Repository.get(`${getColor}`)
  },
}
