import Repository from '@/libs/axios'

const roleResource = '/admin/roles'
const roleDropdownResource = '/admin/roles/list/role-dropdown'

const defaultPagination = 10

export default {
  getRoleList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${roleResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}&ignoreActivity`)
  },
  getRoleListDropdown() {
    return Repository.get(`${roleDropdownResource}`)
  },
  getRoleListNoPagination(filterQuery) {
    return Repository.get(`${roleResource}?${filterQuery}&noPagination`)
  },
  getRoleDetails(id) {
    return Repository.get(`${roleResource}/${id}`)
  },
  createRole(payload) {
    return Repository.post(`${roleResource}`, payload)
  },
  updateRole(id, payload) {
    return Repository.put(`${roleResource}/${id}`, payload)
  },
  setActiveStatus(id, payload) {
    return Repository.put(`${roleResource}/${id}/active-status`, payload)
  },
  deleteRole(id) {
    return Repository.delete(`${roleResource}/${id}`)
  },
}
