import Repository from '@/libs/axios'

const RiderResource = '/admin/rider'
export default {
  createRider(payload) {
    return Repository.get(`${RiderResource}`, payload)
  },
  getAllRiderList() {
    return Repository.get(`${RiderResource}`)
  },
  getAllRiderDropDown() {
    return Repository.get(`${RiderResource}?noPagination`)
  },
  getDriverListForDropdown() {
    return Repository.get(`${RiderResource}/drivers`)
  },
}
