import Repository from '@/libs/axios'

const resource = '/admin/staff'

export default {
  getStaffDetails(id) {
    return Repository.get(`${resource}/${id}`)
  },
  createStaff(payload) {
    return Repository.post(`${resource}`, payload)
  },
  updateStaff(id, payload) {
    return Repository.put(`${resource}/${id}`, payload)
  },
  staffUserResetPassword(id, payload) {
    return Repository.put(`${resource}/${id}/reset-password`, payload)
  },
  staffUserChangePassword(payload) {
    return Repository.post(`${resource}/change-password`, payload)
  },
}
