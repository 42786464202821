export default {
  async validateType(file, fileTypes, customMessage = null) {
    if (fileTypes.includes(file)) {
      return {
        status: true,
        message: customMessage != null ? customMessage : 'File type is valid',
      }
    }
    return {
      status: false,
      message: customMessage != null ? customMessage : 'File type is not valid.',
    }
  },
  async validateSize(file, fileSize, customMessage = null) {
    if (parseInt(file, 10) < fileSize) {
      return {
        status: true,
        message: customMessage != null ? customMessage : 'File size is less than given size',
      }
    }
    return {
      status: false,
      message: customMessage != null ? customMessage : `Allowed maximum file size is ${fileSize / 1048576} Mb`,
    }
  },
  async resolutionValidate(width, height, fixWidth, fixHeight, customMessage = null) {
    if (width === fixWidth && height === fixHeight) {
      return {
        status: true,
        message: customMessage != null ? customMessage : 'File resolution matched',
      }
    }
    return {
      status: false,
      message: customMessage != null ? customMessage : `Image width and height should ${fixWidth}px and ${fixHeight}px!`,
    }
  },
}
