import Repository from '@/libs/axios'
import ListsRepository from '@/libs/customAxios/listsAxios'

const cityResource = '/admin/city'
const cityDataResource = '/admin/city'
const countryResource = '/admin/country?sort=name'
const stateResource = '/admin/state'
const branchResource = '/admin/warehouse/branch'
const agencyResource = '/admin/warehouse/agent'
const zoneResource = '/admin/zone'
const currencyResource = '/admin/currency'
const permissionResource = '/admin/permissions'
const cityTreeResource = '/admin/city/city-list-tree'
const staffResource = '/admin/staff'
const roleResource = '/admin/roles'
const warehouseResource = '/admin/warehouse'
const cityDropdownResource = 'admin/city'
const stateDropdownResource = 'admin/state'
const stateDropdownListResource = 'admin/state/list/statedropdown'
const zoneDropdownResource = 'admin/zone/list/zonedropdown'
const agencyDropdownResource = 'admin/warehouse/list/agentdropdown'
const branchDropdownResource = 'admin/warehouse/list/branchdropdown'
const reasonDropdownResource = 'admin/reason'
const countryByCityResource = '/admin/city/city-list-by-country'
const flagResource = '/admin/flag'

const defaultPagination = 10

export default {
  getCityList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort, ignoreActivity) {
    return ListsRepository.get(`${cityResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}${ignoreActivity ? '&ignoreActivity' : ''}`)
  },

  getCityListNoPagination(filterQuery, sort, ignoreActivity) {
    return Repository.get(`${cityResource}?sort=${sort}&${filterQuery}&noPaginationAllowFilter${ignoreActivity ? '&ignoreActivity' : ''}`)
  },

  getCityById(id) {
    return Repository.get(`${cityResource}/${id}`)
  },

  getWarehouseByCity(id) {
    return Repository.get(`${warehouseResource}/dropdown/warehouses-by-city/${id}&?dropdownList`)
  },

  getAllStateList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${stateResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}`)
  },
  getAllStateListListNoPagination(filterQuery, sort) {
    return Repository.get(`${stateResource}?sort=${sort}&${filterQuery}&noPagination`)
  },

  getAllZoneList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${zoneResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}`)
  },

  getAllZoneListNoPagination(filterQuery, sort) {
    return Repository.get(`${zoneResource}?sort=${sort}&${filterQuery}&noPagination`)
  },

  getAllBranchList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${branchResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}`)
  },

  getAllBranchListNoPagination(filterQuery, sort) {
    return Repository.get(`${branchResource}?sort=${sort}&${filterQuery}&noPagination`)
  },

  getAllStaffList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${staffResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}`)
  },

  getAllStaffListNoPagination(filterQuery) {
    return Repository.get(`${staffResource}?${filterQuery}&noPagination`)
  },

  getAllStaffListByRoleId(pageNumber = 1, id, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${staffResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&filter[role_id]=${id}&${filterQuery}`)
  },
  getAllStaffListByRoleIdNoPagination(id, filterQuery, sort) {
    return Repository.get(`${staffResource}?sort=${sort}&filter[role_id]=${id}&${filterQuery}&noPagination`)
  },

  getZoneDetails(id) {
    return Repository.get(`${zoneResource}/${id}`)
  },

  getBranchDetails(id) {
    return Repository.get(`${branchResource}/${id}`)
  },

  getRoleDetails() {
    return Repository.get(`${roleResource}`)
  },

  getAgencyDetails(id) {
    return Repository.get(`${agencyResource}/${id}`)
  },
  getFlagList() {
    return Repository.get(`${flagResource}/flag-dropdown`)
  },

  getAllAgencyList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${agencyResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}`)
  },

  getAllAgencyListNoPagination(filterQuery, sort) {
    return Repository.get(`${agencyResource}?sort=${sort}&${filterQuery}&noPagination`)
  },

  createCity(payload) {
    return Repository.post(`${cityResource}`, payload)
  },
  createExcelCity(payload) {
    return Repository.post(`${cityResource}/bulk`, payload)
  },

  createZone(payload) {
    return Repository.post(`${zoneResource}`, payload)
  },
  createStaff(payload) {
    return Repository.post(`${staffResource}`, payload)
  },
  createAgency(payload) {
    return Repository.post(`${agencyResource}`, payload)
  },
  createBranch(payload) {
    return Repository.post(`${branchResource}`, payload)
  },
  updateZone(id, payload) {
    return Repository.put(`${zoneResource}/${id}`, payload)
  },
  deleteZone(id) {
    return Repository.delete(`${zoneResource}/${id}`)
  },
  updateCity(id, payload) {
    return Repository.put(`${cityDataResource}/${id}`, payload)
  },
  assignDefaultWarehouse(payload) {
    return Repository.post(`${cityResource}/assign-default-warehouse`, payload)
  },
  updateStaff(id, payload) {
    return Repository.put(`/admin/staff/${id}`, payload)
  },
  updateBranch(id, payload) {
    return Repository.put(`${branchResource}/${id}`, payload)
  },
  updateBranchLogo(id, payload) {
    const formData = new FormData()
    formData.append('file', payload)
    return Repository.post(`${branchResource}/${id}/logo`, formData)
  },
  updateAgent(id, payload) {
    return Repository.put(`${agencyResource}/${id}`, payload)
  },
  updateAgentLogo(id, payload) {
    const formData = new FormData()
    formData.append('file', payload)
    return Repository.post(`${agencyResource}/${id}/logo`, formData)
  },
  getCountryList() {
    return Repository.get(`${countryResource}`)
  },
  getPermissionTree() {
    return Repository.get(`${permissionResource}`)
  },
  getCityDataList() {
    return ListsRepository.get(`${cityDataResource}`)
  },
  getDistrictList() {
    return Repository.get(`${cityTreeResource}`)
  },
  createState(payload) {
    return Repository.post(`${stateResource}`, payload)
  },
  getStateAllList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${stateResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}`)
  },
  deleteState(id) {
    return Repository.delete(`${stateResource}/${id}`)
  },
  getStateDetails(id) {
    return Repository.get(`${stateResource}/${id}`)
  },
  updateState(id, payload) {
    return Repository.put(`${stateResource}/${id}`, payload)
  },
  createBulkStates(payload) {
    return Repository.post(`${stateResource}/bulk`, payload)
  },
  getCityByCountry() {
    return Repository.get(`${countryByCityResource}`)
  },
  getCurrencyList() {
    return Repository.get(`${currencyResource}`)
  },
  getZoneListDropdown() {
    return Repository.get(`${zoneDropdownResource}`)
  },
  getWarehouseList(pagination = defaultPagination, pageNumber = 1) {
    return Repository.get(`${warehouseResource}/list?paginate=${pagination}&page=${pageNumber}`)
  },
  getWarehouseListForDropDown() {
    return Repository.get(`${warehouseResource}/dropdown/list`)
  },
  getAllWarehouseListForDropDown() {
    return Repository.get(`${warehouseResource}/dropdown/list?get_all`)
  },
  getAssignedWarehouses(pagination = defaultPagination, pageNumber = 1) {
    return Repository.get(`${warehouseResource}/assigned-warehouses?paginate=${pagination}&page=${pageNumber}`)
  },
  getAssignedWarehousesNoPagination() {
    return Repository.get(`${warehouseResource}/assigned-warehouses?noPagination`)
  },
  getCityDropdownList() {
    return ListsRepository.get(`${cityDropdownResource}/dropdown/list`)
  },
  getCityDropdownListById(filter, id) {
    return ListsRepository.get(`${cityDropdownResource}?filter[name]=${filter}&filter[id]=${id}&noPagination`)
  },
  getCityDropdownListByState(state, filter) {
    return ListsRepository.get(`${cityDropdownResource}?filter[state_id]=${state}&filter[name]=${filter}&noPagination`)
  },
  getCityDropdownListNoFilter() {
    return ListsRepository.get(`${cityDropdownResource}/dropdown/list`)
  },
  getStateDropdownList(countryId = '') {
    return Repository.get(`${stateDropdownResource}?filter[country_id]=${countryId}&noPagination`)
  },
  getAgencyListDropdown() {
    return Repository.get(`${agencyDropdownResource}`)
  },
  getBranchListDropdown() {
    return Repository.get(`${branchDropdownResource}`)
  },
  getStateListDropdown() {
    return Repository.get(`${stateDropdownListResource}`)
  },
  getReasonListForDropDown(key = '') {
    return Repository.get(`${reasonDropdownResource}/status?status_key=${key}&noPagination`)
  },
  fetchExcelTemplate(url) {
    return Repository.get(url)
  },
  activateCities(payload) {
    return Repository.post(`${cityResource}/activate`, payload)
  },
  deactivateCities(payload) {
    return Repository.post(`${cityResource}/deactivate`, payload)
  },
  getCityDropdownListNoPagination(state, filter) {
    return Repository.get(`${cityDropdownResource}/dropdown/list?filter[state_id]=${state}&filter[name]=${filter}&noPagination`)
  },
}
