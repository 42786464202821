import Repository from '@/libs/axios'

const remarkResource = '/admin/order'

export default {
  getRemarkList(id) {
    return Repository.get(`${remarkResource}/${id}/remark`)
  },
  createRemark(id, payload) {
    return Repository.post(`${remarkResource}/${id}/remark`, payload)
  },
  updateRemark(id, remarkId, payload) {
    return Repository.put(`${remarkResource}/${id}/remark/${remarkId}`, payload)
  },
  deleteRemark(id) {
    return Repository.delete(`${remarkResource}/remark/${id}`)
  },
  markAsRead(id) {
    return Repository.post(`${remarkResource}/${id}/remark/mark-as-read`)
  },
}
