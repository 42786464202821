import Repository from '@/libs/axios'

const reasonResource = '/admin/reason'
const statusResource = '/admin/status?filter[status]=key_14,key_15,key_17,key_3,'

const defaultPagination = 10

export default {
  getReasonList(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return Repository.get(`${reasonResource}?page=${pageNumber}&paginate=${pagination}&${filterQuery}`)
  },
  getReasonListNoPagination(filterQuery) {
    return Repository.get(`${reasonResource}?${filterQuery}&noPagination`)
  },
  getStatusList() {
    return Repository.get(`${statusResource}`)
  },
  getReasonDetails(id) {
    return Repository.get(`${reasonResource}/${id}`)
  },
  createReason(payload) {
    return Repository.post(`${reasonResource}`, payload)
  },
  updateReason(id, payload) {
    return Repository.put(`${reasonResource}/${id}`, payload)
  },
}
