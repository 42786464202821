import Repository from '@/libs/axios'
import ListsRepository from '@/libs/customAxios/listsAxios'

const financeStatusResource = '/admin/finance/status'
const riderFinanceResource = '/admin/finance/rider'
const riderFinanceCommissionResource = '/admin/finance/rider-commission'
const riderFinanceDashResource = '/admin/finance/riders'
const riderFinanceDepositResource = '/admin/finance/rider-deposit'
const agencyFinanceResource = '/admin/finance/agency'
const agencyFinanceCommissionResource = '/admin/finance/agency-commission'
const agentFinanceDashResource = '/admin/finance/agencies'
const agencyFinanceDepositResource = '/admin/finance/agency-deposit'
const branchFinanceResource = '/admin/finance/branch'
const branchFinanceDashResource = '/admin/finance/branches'
const branchFinanceDepositResource = '/admin/finance/branch-deposit'
const merchantFinanceResource = '/admin/finance/merchant'
const merchantBusinessFinanceResource = '/admin/finance/merchant-business'
const merchantDashFinanceResource = '/admin/finance/merchants'
const merchantFinanceDepositResource = '/admin/finance/merchant-invoice'
const paymentMethodResource = '/admin/payment-method'
const expensesResource = '/admin/expenses'

const defaultPagination = 10
export default {
  // Common
  getPaymentMethod() {
    return Repository.get(`${paymentMethodResource}`)
  },
  getExpenseType() {
    return Repository.get(`${expensesResource}`)
  },
  getFinanceStatusList() {
    return Repository.get(`${financeStatusResource}`)
  },
  // Rider
  createRiderFinanceDeposit(payload) {
    return Repository.post(`${riderFinanceResource}/store-by-staff`, payload)
  },
  createRiderFinanceCommissionInvoice(payload) {
    return Repository.post(`${riderFinanceCommissionResource}/generate-commission-invoice`, payload)
  },
  getRiderFinanceDepositList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return ListsRepository.get(`${riderFinanceResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}&sort=-id`)
  },
  getRiderFinanceDepositListNoPagination(filterQuery) {
    return Repository.get(`${riderFinanceResource}?${filterQuery}&sort=-id&noPagination`)
  },
  getRiderFinanceCommissionList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return ListsRepository.get(`${riderFinanceCommissionResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}&sort=-id`)
  },
  getRiderFinanceCommissionListNoPagination(filterQuery) {
    return Repository.get(`${riderFinanceCommissionResource}?${filterQuery}&sort=-id&noPagination`)
  },
  getRiderOverviewFinance(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${riderFinanceDashResource}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}&sort=-id`)
  },
  getRiderOverviewFinanceNoPagination(attribute, key, filterQuery) {
    return ListsRepository.get(`${riderFinanceDashResource}/orders?${filterQuery}${attribute ? `&filter[${attribute}]=${key}` : ''}&sort=-id&noPagination`)
  },
  getRiderOverviewFinanceByKey(pageNumber = 1, attribute, key, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${riderFinanceDashResource}/orders?paginate=${pagination}&page=${pageNumber}&filter[${attribute}]=${key}&${filterQuery}&sort=-id`)
  },
  getRiderOverviewFinanceByKeyNoPagination(attribute, key, filterQuery) {
    return ListsRepository.get(`${riderFinanceDashResource}/orders?filter[${attribute}]=${key}&${filterQuery}&sort=-id&noPagination`)
  },
  getAllRiderWaybillList(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return ListsRepository.get(`${riderFinanceResource}/${id}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAllRiderWaybillListNoPagination(id, filterQuery) {
    return ListsRepository.get(`${riderFinanceResource}/${id}/orders?${filterQuery}&sort=-id&noPagination`)
  },
  getAllRiderDepositList(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return ListsRepository.get(`${riderFinanceCommissionResource}/${id}/deposits?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAllRiderDepositListNoPagination(id, filterQuery) {
    return ListsRepository.get(`${riderFinanceCommissionResource}/${id}/deposits?${filterQuery}&sort=-id&noPagination`)
  },
  getAllRiderOrdersList(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return ListsRepository.get(`${riderFinanceDepositResource}/${id}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAllRiderOrdersListNoPagination(id, filterQuery) {
    return ListsRepository.get(`${riderFinanceDepositResource}/${id}/orders?${filterQuery}&sort=-id&noPagination`)
  },
  getAllRiderDepositsList(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return ListsRepository.get(`${riderFinanceCommissionResource}/${id}/show-deposits?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAllRiderDepositsListNoPagination(id, filterQuery) {
    return ListsRepository.get(`${riderFinanceCommissionResource}/${id}/show-deposits?${filterQuery}&sort=-id&noPagination`)
  },
  updateStatusRiderFinanceDeposit(payload) {
    return Repository.post(`${riderFinanceResource}/status-update`, payload)
  },
  updateStatusRiderFinanceCommission(payload) {
    return Repository.post(`${riderFinanceCommissionResource}/status-update`, payload)
  },
  uploadRiderFinanceDeposit(id, payload) {
    const formData = new FormData()
    formData.append('file', payload)
    return Repository.post(`${riderFinanceResource}/${id}/deposit-upload`, formData)
  },
  approveStatusRiderFinanceDeposit(payload) {
    return Repository.post(`${riderFinanceResource}/status-update`, payload)
  },
  rejectRiderFinanceDeposit(payload) {
    return Repository.post(`${riderFinanceResource}/reject`, payload)
  },
  rejectRiderFinanceCommission(payload) {
    return Repository.post(`${riderFinanceCommissionResource}/reject`, payload)
  },
  getRiderFinanceDepositRejectionDetails(id) {
    return Repository.get(`${riderFinanceResource}/${id}/rejection-details`)
  },
  getRiderDepositNote(id) {
    return Repository.get(`${riderFinanceResource}/${id}/print-deposit`)
  },
  getRiderCommissionNote(id) {
    return Repository.get(`${riderFinanceCommissionResource}/${id}/print-commission-invoice`)
  },
  getRiderDepositDetailsBeforeSubmit(payload) {
    return Repository.post(`${riderFinanceDepositResource}/selected-order-details`, payload)
  },
  getRiderCommissionDetailsBeforeSubmit(payload) {
    return Repository.post(`${riderFinanceCommissionResource}/selected-deposit-details`, payload)
  },
  // Agent
  createAgentFinanceDeposit(payload) {
    return Repository.post(`${agencyFinanceResource}`, payload)
  },
  createAgentFinanceCommissionInvoice(payload) {
    return Repository.post(`${agencyFinanceCommissionResource}/generate-commission-invoice`, payload)
  },
  getAgentFinanceDepositList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return ListsRepository.get(`${agencyFinanceResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}&sort=-id`)
  },
  getAgentFinanceDepositListNoPagination(filterQuery) {
    return ListsRepository.get(`${agencyFinanceResource}?${filterQuery}&sort=-id&noPagination`)
  },
  getAgentFinanceCommissionList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return ListsRepository.get(`${agencyFinanceCommissionResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}&sort=-id`)
  },
  getAgentFinanceCommissionListNoPagination(filterQuery) {
    return ListsRepository.get(`${agencyFinanceCommissionResource}?${filterQuery}&sort=-id&noPagination`)
  },
  getAllAgentWaybillList(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return ListsRepository.get(`${agencyFinanceResource}/${id}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAllAgentWaybillListNoPagination(id, filterQuery) {
    return ListsRepository.get(`${agencyFinanceResource}/${id}/orders?${filterQuery}&sort=-id&noPagination`)
  },
  getAllAgentDepositsList(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return ListsRepository.get(`${agencyFinanceCommissionResource}/${id}/deposits?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAllAgentDepositsListNoPagination(id, filterQuery) {
    return ListsRepository.get(`${agencyFinanceCommissionResource}/${id}/deposits?${filterQuery}&sort=-id&noPagination`)
  },
  getAllAgentOrderList(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return ListsRepository.get(`${agencyFinanceDepositResource}/${id}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAllAgentOrderListNoPagination(id, filterQuery) {
    return ListsRepository.get(`${agencyFinanceDepositResource}/${id}/orders?${filterQuery}&sort=-id&noPagination`)
  },
  getAllAgentDepositList(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return ListsRepository.get(`${agencyFinanceCommissionResource}/${id}/show-deposits?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAllAgentDepositListNoPagination(id, filterQuery) {
    return ListsRepository.get(`${agencyFinanceCommissionResource}/${id}/show-deposits?${filterQuery}&sort=-id&noPagination`)
  },
  getAgentOverviewFinance(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${agentFinanceDashResource}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}&sort=-id`)
  },
  getAgentOverviewFinanceNoPagination(attribute, key, filterQuery) {
    return ListsRepository.get(`${agentFinanceDashResource}/orders?${filterQuery}${attribute ? `&filter[${attribute}]=${key}` : ''}&sort=-id&noPagination`)
  },
  getAgentOverviewFinanceByKey(pageNumber = 1, attribute, key, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${agentFinanceDashResource}/orders?paginate=${pagination}&page=${pageNumber}&filter[${attribute}]=${key}&${filterQuery}&sort=-id`)
  },
  getAgentOverviewFinanceByKeyNoPagination(attribute, key, filterQuery) {
    return ListsRepository.get(`${agentFinanceDashResource}/orders?filter[${attribute}]=${key}&${filterQuery}&sort=-id&noPagination`)
  },
  updateStatusAgentFinanceDeposit(payload) {
    return Repository.post(`${agencyFinanceResource}/status-update`, payload)
  },
  updateStatusAgentFinanceCommission(payload) {
    return Repository.post(`${agencyFinanceCommissionResource}/status-update`, payload)
  },
  uploadAgentFinanceDeposit(id, payload) {
    const formData = new FormData()
    formData.append('file', payload)
    return Repository.post(`${agencyFinanceResource}/${id}/deposit-upload`, formData)
  },
  approveStatusAgentFinanceDeposit(payload) {
    return Repository.post(`${agencyFinanceResource}/status-update`, payload)
  },
  rejectAgentFinanceDeposit(payload) {
    return Repository.post(`${agencyFinanceResource}/reject`, payload)
  },
  rejectAgentFinanceCommission(payload) {
    return Repository.post(`${agencyFinanceCommissionResource}/reject`, payload)
  },
  getAgentFinanceDepositRejectionDetails(id) {
    return Repository.get(`${agencyFinanceResource}/${id}/rejection-details`)
  },
  getAgentDepositNote(id) {
    return Repository.get(`${agencyFinanceResource}/${id}/print-deposit`)
  },
  getAgentCommissionNote(id) {
    return Repository.get(`${agencyFinanceCommissionResource}/${id}/print-commission-invoice`)
  },
  getAgentDepositDetailsBeforeSubmit(payload) {
    return Repository.post(`${agencyFinanceDepositResource}/selected-order-details`, payload)
  },
  getAgentCommissionDetailsBeforeSubmit(payload) {
    return Repository.post(`${agencyFinanceCommissionResource}/selected-deposit-details`, payload)
  },

  // Branch
  createBranchFinanceDeposit(payload) {
    return Repository.post(`${branchFinanceResource}`, payload)
  },
  getBranchFinanceDepositList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return ListsRepository.get(`${branchFinanceResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}&sort=-id`)
  },
  getBranchFinanceDepositListNoPagination(filterQuery) {
    return Repository.get(`${branchFinanceResource}?${filterQuery}&sort=-id&noPagination`)
  },
  getBranchOverviewFinance(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${branchFinanceDashResource}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}&sort=-id`)
  },
  getBranchOverviewFinanceNoPagination(attribute, key, filterQuery) {
    return ListsRepository.get(`${branchFinanceDashResource}/orders?${filterQuery}${attribute ? `&filter[${attribute}]=${key}` : ''}&sort=-id&noPagination`)
  },
  getBranchOverviewFinanceByKey(pageNumber = 1, attribute, key, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${branchFinanceDashResource}/orders?paginate=${pagination}&page=${pageNumber}&filter[${attribute}]=${key}&${filterQuery}&sort=-id`)
  },
  getBranchOverviewFinanceByKeyNoPagination(attribute, key, filterQuery) {
    return ListsRepository.get(`${branchFinanceDashResource}/orders?filter[${attribute}]=${key}&${filterQuery}&sort=-id&noPagination`)
  },
  getAllBranchWaybillList(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return ListsRepository.get(`${branchFinanceResource}/${id}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAllBranchWaybillListNoPagination(id, filterQuery) {
    return ListsRepository.get(`${branchFinanceResource}/${id}/orders?${filterQuery}&sort=-id&noPagination`)
  },
  getAllBranchOrdersList(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return ListsRepository.get(`${branchFinanceDepositResource}/${id}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAllBranchOrdersListNoPagination(id, filterQuery) {
    return ListsRepository.get(`${branchFinanceDepositResource}/${id}/orders?${filterQuery}&sort=-id&noPagination`)
  },
  updateStatusBranchFinanceDeposit(payload) {
    return Repository.post(`${branchFinanceResource}/status-update`, payload)
  },
  uploadBranchFinanceDeposit(id, payload) {
    const formData = new FormData()
    formData.append('file', payload)
    return Repository.post(`${branchFinanceResource}/${id}/deposit-upload`, formData)
  },
  approveStatusBranchFinanceDeposit(payload) {
    return Repository.post(`${branchFinanceResource}/status-update`, payload)
  },
  rejectBranchFinanceDeposit(payload) {
    return Repository.post(`${branchFinanceResource}/reject`, payload)
  },
  getBranchFinanceDepositRejectionDetails(id) {
    return Repository.get(`${branchFinanceResource}/${id}/rejection-details`)
  },
  getBranchDepositNote(id) {
    return Repository.get(`${branchFinanceResource}/${id}/print-deposit`)
  },
  getBranchDepositDetailsBeforeSubmit(payload) {
    return Repository.post(`${branchFinanceDepositResource}/selected-order-details`, payload)
  },

  // Merchant
  createMerchantInvoice(payload) {
    return Repository.post(`${merchantFinanceResource}`, payload)
  },
  getMerchantFinanceDepositList(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${merchantFinanceResource}?paginate=${pagination}&page=${pageNumber}&${filterQuery}&sort=-id`)
  },
  getMerchantFinanceDepositListNoPagination(filterQuery) {
    return Repository.get(`${merchantFinanceResource}?${filterQuery}&sort=-id&noPagination`)
  },
  updateStatusMerchantFinanceDeposit(payload) {
    return Repository.post(`${merchantFinanceResource}/status-update`, payload)
  },
  uploadMerchantFinanceDeposit(id, payload) {
    const formData = new FormData()
    formData.append('file', payload)
    return Repository.post(`${merchantFinanceResource}/${id}/deposit-upload`, formData)
  },
  approveStatusMerchantFinanceDeposit(payload) {
    return Repository.post(`${merchantFinanceResource}/status-update`, payload)
  },
  getAllOrdersList(id) {
    return ListsRepository.get(`${merchantFinanceResource}/${id}/orders`)
  },
  getMerchantOverviewFinance(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${merchantDashFinanceResource}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}&sort=-id`)
  },
  getMerchantOverviewFinanceNoPagination(attribute, key, filterQuery) {
    return ListsRepository.get(`${merchantDashFinanceResource}/orders?${filterQuery}${attribute ? `&filter[${attribute}]=${key}` : ''}&sort=-id&noPagination`)
  },
  getMerchantOverviewFinanceByKey(pageNumber = 1, attribute, key, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${merchantDashFinanceResource}/orders?paginate=${pagination}&page=${pageNumber}&filter[${attribute}]=${key}&${filterQuery}&sort=-id`)
  },
  getMerchantOverviewFinanceByKeyNoPagination(attribute, key, filterQuery) {
    return ListsRepository.get(`${merchantDashFinanceResource}/orders?filter[${attribute}]=${key}&${filterQuery}&sort=-id&noPagination`)
  },
  getAllMechantOrdersList(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return ListsRepository.get(`${merchantFinanceDepositResource}/${id}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getAllMechantOrdersListNoPagination(id) {
    return Repository.get(`${merchantFinanceDepositResource}/${id}/orders?noPagination`)
  },
  getMerchantInvoiceOrders(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return Repository.get(`${merchantFinanceResource}/${id}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getMerchantInvoiceOrdersNoPagination(id, filterQuery) {
    return ListsRepository.get(`${merchantFinanceResource}/${id}/orders?${filterQuery}&sort=-id&noPagination`)
  },
  getMerchantBusinessInvoiceOrders(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return Repository.get(`${merchantBusinessFinanceResource}/${id}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getMerchantBusinessInvoiceOrdersNoPagination(id, filterQuery) {
    return ListsRepository.get(`${merchantBusinessFinanceResource}/${id}/orders?${filterQuery}&sort=-id&noPagination`)
  },
  getAllOrdersMerchantList(id) {
    return ListsRepository.get(`${merchantFinanceResource}/${id}`)
  },
  getInvoiceData(id) {
    return Repository.get(`${merchantFinanceResource}/${id}/print-invoice`)
  },
  getInvoiceDetailsBeforeSubmit(payload) {
    return Repository.post(`${merchantFinanceDepositResource}/selected-order-details`, payload)
  },
  getInvoiceRejectionDetails(id) {
    return Repository.get(`${merchantFinanceResource}/${id}/rejection-details`)
  },
  createExpense(depositId, payload) {
    return Repository.post(`${branchFinanceResource}/deposit/${depositId}/expenses`, payload)
  },
  getExpenseList(depositId, pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return Repository.get(`${branchFinanceResource}/deposit/${depositId}/expenses?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getExpenseAllData(depositId, filterQuery) {
    return ListsRepository.get(`${branchFinanceResource}/deposit/${depositId}/expenses?${filterQuery}&sort=-id&noPagination`)
  },
  getExpenseListNoPagination() {
    return Repository.get(`${expensesResource}`)
  },
}
