// import Repository from '@/libs/axios'
import DashboardRepository from '@/libs/customAxios/dashboardAxios'

const dashboardResource = '/admin/dashboard'

// const defaultPagination = 10
export default {
  getOrderStatsFinance(id) {
    return DashboardRepository.get(`${dashboardResource}/${id}/order-statistics`)
  },
  getFinancialStatFinance(id) {
    return DashboardRepository.get(`${dashboardResource}/${id}/financial-statistics`)
  },
  getMonthlyOrdersFinance(id) {
    return DashboardRepository.get(`${dashboardResource}/${id}/monthly-orders`)
  },
  getMonthlyEarningsFinance(id) {
    return DashboardRepository.get(`${dashboardResource}/${id}/monthly-earning`)
  },
  getCODRecivableFinance(id) {
    return DashboardRepository.get(`${dashboardResource}/${id}/cod-receivable`)
  },
  getToBEInvoicesFinance(id) {
    return DashboardRepository.get(`${dashboardResource}/${id}/to-be-invoice`)
  },
  // getMerchantOnBoardingFinance(id) {
  //   return Repository.get(`${orderMerchantOnBoardingFinanceResource}/${id}/merchant-on-boarding`)
  // },
  getStatusStatisticsFinance(id) {
    return DashboardRepository.get(`${dashboardResource}/${id}/status-statistics`)
  },
  // City Wise Delivery Statistics In Selected Warehouse
  getCityWiseStats(id) {
    return DashboardRepository.get(`${dashboardResource}/${id}/city-wise-stats`)
  },
}
