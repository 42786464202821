import Repository from '@/libs/axios'

const waybillRequestResource = '/admin/waybill'

const defaultPagination = 10

export default {
  getWaybillRequestList(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return Repository.get(`${waybillRequestResource}?page=${pageNumber}&paginate=${pagination}&${filterQuery}`)
  },
  getWaybillRequestListNoPagination(filterQuery) {
    return Repository.get(`${waybillRequestResource}?${filterQuery}&noPagination`)
  },
  getWaybillRequestDetailsbyID(id) {
    return Repository.get(`${waybillRequestResource}/${id}`)
  },
  updateWaybillRequest(id, payload) {
    return Repository.put(`${waybillRequestResource}/${id}/confirm`, payload)
  },
  rejectWaybillRequest(id, payload) {
    return Repository.put(`${waybillRequestResource}/${id}/reject`, payload)
  },
}
