import Repository from '@/libs/axios'

const merchantSettingsResource = '/admin/settings/merchant'
const termsAndConditionResource = '/admin/terms-and-condition'

export default {
  getMerchantSettingsResource() {
    return Repository.get(`${merchantSettingsResource}`)
  },
  updateMerchantSettingsResource(payload) {
    return Repository.put(`${merchantSettingsResource}`, payload)
  },
  fetchTermsAndConditionsVersions(filterQuery) {
    return Repository.get(`${termsAndConditionResource}?${filterQuery}`)
  },
  onClickDelete(id) {
    return Repository.delete(`${termsAndConditionResource}/${id}`)
  },
  storeTermsAndCondition(payload) {
    return Repository.post(`${termsAndConditionResource}`, payload)
  },
  onClickEditTermsAndCondition(id) {
    return Repository.get(`${termsAndConditionResource}/${id}`)
  },
  updateTermsAndCondition(payload) {
    return Repository.put(`${termsAndConditionResource}/update`, payload)
  },
  storeTermsAndConditionPdf(payload) {
    return Repository.post(`${termsAndConditionResource}/store-pdf`, payload)
  },
}
