/* eslint-disable import/extensions */
import rateCardRepository from './rateCardRepository'
import staffAuthRepository from './staffAuthRepository'
import resourceRepository from './resourceRepository'
import roleRepository from './roleRepository'
import staffRepository from './staffRepository'
import merchantRepository from './merchantRepository'
import merchantRoleRepository from './merchantRoleRepository'
import riderRepository from './riderRepository'
import financeRepository from './financeRepository'
import dashBoardRepository from './dashBoardRepository'
import warehouseDashBoardRepository from './warehouseDashBoardRepository'
import overviewDashBoardRepository from './overviewDashBoardRepository'
import reportRepository from './reportRepository'
import orderRepository from './orderRepository'
import notificationRepository from './notificationRepository.js'
import businessSettingsRepository from './businessSettingsRepository'
import orderSettingsRepository from './orderSettingsRepository'
import financeSettingsRepository from './financeSettingsRepository'
import notificationSettingsRepository from './notificationSettingsRepository'
import smsSettingsRepository from './smsSettingsRepository'
import emailSettingsRepository from './emailSettingsRepository'
import layoutSettingRepository from './layoutSettingRepository'
import walkingCustomerRepository from './walkingCustomerRepository'
import reasonRepository from './reasonRepository'
import remarkRepository from './remarkRepository'
import subscriptionRepository from './subscriptionRepository'
import quickbookRepository from './quickbookRepository'
import cacheRepository from './cacheRepository'
import ticketRepository from './ticketRepository'
import feedbackRepository from './feedbackRepository'
import statusSettingRepository from './statusSettingsRepository'
import chatRepository from './chatRepository'
import taxSettingRepository from './taxSettingRepository'
import TrackingSettingRepository from './TrackingSettingRepository'
import waybillRequestRepository from './waybillRequestRepository'
import packageRepository from './packagesRepository'
import merchantSettingsRepository from './merchantSettingsRepository'
import colorThemeRepository from './ThemeColorRepository'
import alertRepository from './alertRepository'
import flagRepository from './flagRepository'
import waybillSettingRepository from './waybillSettingRepository'
import bannerRepository from './bannerRepository'
import approvalSettingsRepository from './approvalSettingsRepository'
import genarelSearchRepository from './genarelSearchRepository'

const repositories = {
  staffauth: staffAuthRepository,
  resource: resourceRepository,
  role: roleRepository,
  ratecard: rateCardRepository,
  staff: staffRepository,
  merchant: merchantRepository,
  merchantRole: merchantRoleRepository,
  rider: riderRepository,
  finance: financeRepository,
  dashboard: dashBoardRepository,
  report: reportRepository,
  warehousedashboard: warehouseDashBoardRepository,
  overviewdashboard: overviewDashBoardRepository,
  order: orderRepository,
  notification: notificationRepository,
  businessSetting: businessSettingsRepository,
  orderSetting: orderSettingsRepository,
  financeSetting: financeSettingsRepository,
  notificationSetting: notificationSettingsRepository,
  smsSetting: smsSettingsRepository,
  emailSetting: emailSettingsRepository,
  layout: layoutSettingRepository,
  walkingCustomer: walkingCustomerRepository,
  reasons: reasonRepository,
  remark: remarkRepository,
  subscription: subscriptionRepository,
  quickbook: quickbookRepository,
  cache: cacheRepository,
  ticket: ticketRepository,
  feedback: feedbackRepository,
  statusSetting: statusSettingRepository,
  chat: chatRepository,
  taxSetting: taxSettingRepository,
  trackingSetting: TrackingSettingRepository,
  waybill: waybillRequestRepository,
  package: packageRepository,
  merchantSetting: merchantSettingsRepository,
  colorTheme: colorThemeRepository,
  alert: alertRepository,
  flag: flagRepository,
  waybillSetting: waybillSettingRepository,
  banner: bannerRepository,
  approvalSettings: approvalSettingsRepository,
  genarelSearch: genarelSearchRepository,
}

// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
  get: name => repositories[name],
}
