import Repository from '@/libs/axios'

const roleResource = '/merchant/roles'

export default {
  getRoleDataList(id) {
    return Repository.get(`admin/merchant/${id}/roles`)
  },
  getRoleDetails(id) {
    return Repository.get(`${roleResource}/${id}`)
  },
}
