import Repository from '@/libs/axios'

const packageSubscriptionResource = '/packagesubscription/tenant'
const subscribe = '/packagesubscription/tenant/subscribe'
const payamentSlipUpload = 'packagesubscription/tenant/upload/subscription-payment-slip'

const defaultPagination = 10

export default {
  getSubscriptionList(pageNumber = 1, pagination = defaultPagination) {
    return Repository.get(`${packageSubscriptionResource}/subscription-history?paginate=${pagination}&page=${pageNumber}`)
  },
  getSubscriptionListNopagination(filterQuery) {
    return Repository.get(`${packageSubscriptionResource}/subscription-history?${filterQuery}&sort=-id&noPagination`)
  },
  getSubscriptionDataById(id) {
    return Repository.get(`${packageSubscriptionResource}/subscription-history/${id}`)
  },
  getBillingList(pageNumber = 1, pagination = defaultPagination) {
    return Repository.get(`${packageSubscriptionResource}/subscription-invoice-history?page=${pageNumber}&paginate=${pagination}`)
  },
  getBillingListNopagination(filterQuery) {
    return Repository.get(`${packageSubscriptionResource}/subscription-invoice-history?${filterQuery}&sort=-id&noPagination`)
  },
  saveInvoiceImage(formData) {
    return Repository.post(`${payamentSlipUpload}`, formData)
  },
  getSubscriptionById(id) {
    return Repository.get(`${packageSubscriptionResource}/subscription-invoice/${id}`)
  },
  packageSubscribe(payload) {
    return Repository.post(`${subscribe}`, payload)
  },
  getActiveSubscription() {
    return Repository.get(`${packageSubscriptionResource}/active-subscription`)
  },
  getActiveSubscriptionUsage() {
    return Repository.get(`${packageSubscriptionResource}/active-subscription-usage`)
  },
  getInvoiceData(id) {
    return Repository.get(`${packageSubscriptionResource}/subscription-invoice/${id}`)
  },
  // getSubscriptionPackages(id) {
  //   return Repository.get(`${subscriptionResource}/${id}/packages`)
  // },
  // getSubscriptionPackageList() {
  //   return Repository.get(`${subscriptionResource}/packages`)
  // },
  // getSubscriptionPackageById(id) {
  //   return Repository.get(`${subscriptionResource}/packages/${id}`)
  // },
}
