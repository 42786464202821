import Repository from '@/libs/axios'

const resource = 'admin/chat'

const defaultPagination = 10

export default {
  getChatrooms(filterQuery) {
    return Repository.get(`${resource}/chatroom?${filterQuery}&noPagination`)
  },
  createChatroom(payload) {
    return Repository.post(`${resource}/chatroom`, payload)
  },
  deleteChatroom(id) {
    return Repository.delete(`${resource}/chatroom/${id}`)
  },
  getChatroomParticipants(id) {
    return Repository.get(`${resource}/chatroom/${id}/participants`)
  },
  addChatroomParticipant(id, payload) {
    return Repository.post(`${resource}/chatroom/${id}/add-participant`, payload)
  },
  removeChatroomParticipant(id, payload) {
    return Repository.post(`${resource}/chatroom/${id}/remove-participant`, payload)
  },
  getChatroomChats(chatroomId, pageNumber = 1, pagination = defaultPagination) {
    return Repository.get(`${resource}/chatroom/${chatroomId}/message?page=${pageNumber}&paginate=${pagination}`)
  },
  getChatroomChatsNoPagination(chatroomId) {
    return Repository.get(`${resource}/chatroom/${chatroomId}/message?noPagination`)
  },
  sendMessage(chatroomId, payload) {
    return Repository.post(`${resource}/chatroom/${chatroomId}/message`, payload)
  },
  deleteMessage(id) {
    return Repository.post(`${resource}/message/${id}`)
  },
  clearChatroomChats(id) {
    return Repository.delete(`${resource}/chatroom/${id}/message-clear`)
  },
}
