/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import Repository from '@/libs/axios'

const feedbackResource = '/admin/feedback'
const customerFeedbackResource = '/rate-us'
const customerFeedbackViewResource = '/admin/customer-feedback'
const allFeedbackViewResource = '/admin/report/customer-feedback-report'

const defaultPagination = 5

export default {
  getFeedback(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return Repository.get(`${feedbackResource}?${filterQuery}&page=${pageNumber}&paginate=${pagination}`)
  },
  getAllFeedback(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return Repository.get(`${allFeedbackViewResource}?${filterQuery}&page=${pageNumber}&paginate=${pagination}`)
  },
  getFeedbackNoPagination(filterQuery) {
    return Repository.get(`${allFeedbackViewResource}?${filterQuery}&noPagination`)
  },
  getFeedbackSetting() {
    return Repository.get(`${feedbackResource}/setting`)
  },
  updateFeedbackSetting(payload) {
    return Repository.put(`${feedbackResource}/setting`, payload)
  },
  getOrderDetailsByCode(code) {
    return Repository.get(`${customerFeedbackResource}/${code}`)
  },
  saveFeedbackByCode(code, payload) {
    return Repository.post(`${customerFeedbackResource}/${code}`, payload)
  },
  getFeedbackDetails(id) {
    return Repository.get(`${customerFeedbackViewResource}/${id}`)
  },
}
