import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueFormulate from '@braid/vue-formulate'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import { TreeViewPlugin } from '@syncfusion/ej2-vue-navigations'
import JsonExcel from 'vue-json-excel'
import LetItSnow from 'vue-let-it-snow'
import VueIdentifyNetwork from 'vue-identify-network'
import moment from 'moment'
import mdiVue from 'mdi-vue'
import * as mdijs from '@mdi/js'
import VueExcelEditor from 'vue-excel-editor'
import i18n from '@/libs/i18n'
import authController from '@/router/auth_controller'
import VueSweetalert2 from 'vue-sweetalert2'
// eslint-disable-next-line camelcase
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import { faXmark } from '@fortawesome/free-solid-svg-icons'
// eslint-disable-next-line camelcase
import laravel_acl from './libs/acl/laravel-acl'
import store from './store'
import router from './router'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vee-validation'
import '@/libs/vue-lodash'
import '@/libs/vue-select'
import ReportTableButtonsVue from './components/ReportTableUtils/ReportButtons/ReportTableButtons.vue'
import TableDataFetching from './components/TableDataFetching.vue'
import validate from './@core/utils/validations/validate'
import './theme-setup'

Vue.mixin({
  methods: {
    // eslint-disable-next-line no-underscore-dangle
    __dateTimeFormatter(date) {
      if (date) {
        return moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss')
      }
      return ''
    },
    // eslint-disable-next-line no-underscore-dangle
    __dateTimeFormatterPassedTime(date) {
      const time = new Date(date)
      const now = new Date()
      const diff = now - time
      const seconds = Math.floor(diff / 1000)
      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)
      const months = Math.floor(days / 30)
      const years = Math.floor(months / 12)
      if (seconds < 60) return `${seconds} seconds ago`
      if (minutes < 60) return `${minutes} minutes ago`
      if (hours < 24) return `${hours} hours ago`
      if (days < 30) return `${days} days ago`
      if (months < 12) return `${months} months ago`
      return `${years} years ago`
    },
    // eslint-disable-next-line no-underscore-dangle
    __numberWithCommas(x, decimalPoints = false) {
      if (x) {
        if (decimalPoints) {
          // eslint-disable-next-line no-param-reassign
          x = parseFloat(x).toFixed(2)
          // eslint-disable-next-line no-param-reassign
          x = x.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 })
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return decimalPoints ? '0.00' : '0'
    },
    // eslint-disable-next-line no-underscore-dangle
    __focusInputField(name) {
      const formElements = this.$refs.formbuilder.$children[0].$children
      formElements.forEach(formElement => {
        if (formElement.name === name) {
          formElement.$children[0].$children[0].$el.focus()
        }
      })
    },
    // eslint-disable-next-line no-underscore-dangle
    __toggleDisableInput(name, disabled) {
      const formElements = this.$refs.formbuilder.$children[0].$children
      formElements.forEach(formElement => {
        if (formElement.name === name) {
          // eslint-disable-next-line no-param-reassign
          formElement.$children[0].$children[0].$el.disabled = disabled
        }
      })
    },
    // eslint-disable-next-line no-underscore-dangle
    __refineDateQuery(filterQuery, searchKeys) {
      searchKeys.forEach(searchKey => {
        if (filterQuery.includes(searchKey)) {
          const startIndex = filterQuery.indexOf(searchKey)
          let singleDateSelected = true
          try {
            if (filterQuery.substring(startIndex, startIndex + searchKey.length + 17).includes('to')) {
              singleDateSelected = false
            }
          } catch (e) {
            singleDateSelected = true
          }
          if (singleDateSelected) {
            const dateQuery = filterQuery.substring(startIndex, startIndex + searchKey.length + 11)
            // eslint-disable-next-line no-param-reassign
            filterQuery = filterQuery.replaceAll(dateQuery, '')
            const date = dateQuery.split('=')[1]
            // eslint-disable-next-line no-param-reassign
            filterQuery += `&${searchKey}=${date}%20to%20${date}`
          }
        }
      })
      return filterQuery
    },
    // eslint-disable-next-line no-underscore-dangle
    __preserveTabOnRefresh(refKey, sessionKey) {
      this.$refs[refKey].currentTab = Number(sessionStorage.getItem(sessionKey)) || 0
      sessionStorage.setItem(sessionKey, '')
      window.onbeforeunload = () => {
        sessionStorage.setItem(sessionKey, this.$refs[refKey].currentTab)
      }
    },
    // eslint-disable-next-line no-underscore-dangle
    __showSweetAlert(title, message) {
      this.$swal({
        title,
        text: message,
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'Okay',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    },
    // eslint-disable-next-line no-underscore-dangle
    __showJsonDownloadAlertForAllOrders() {
      // eslint-disable-next-line no-underscore-dangle
      this.__showSweetAlert('Your Data Is Processing...', 'Please check your email shortly, relevant data will be sent to you.')
    },
    // eslint-disable-next-line no-underscore-dangle
    __showJsonDownloadAlert() {
      // eslint-disable-next-line no-underscore-dangle
      this.__showSweetAlert('Downloading Data', 'This process may take some time. You may close this window and continue working')
    },
    // eslint-disable-next-line no-underscore-dangle
    __showRateCardCloneUserAlert() {
      // eslint-disable-next-line no-underscore-dangle
      this.__showSweetAlert('Rate Card Clone User', 'Previous clone process not complete yet. Please try again later.')
    },
    // eslint-disable-next-line no-underscore-dangle
    __clearCache() {
      const zoomVal = localStorage.getItem('browser_zoom')
      localStorage.clear()
      localStorage.setItem('browser_zoom', zoomVal)
    },
    // eslint-disable-next-line no-underscore-dangle
    __orderMerchantName(order) {
      // eslint-disable-next-line no-nested-ternary
      return `${order.merchant.name}${order.merchant.merchant_setting ? (order.merchant.merchant_setting.merchant_type === 'cash' ? ' [C]' : '') : ' [WC]'}`
    },
    // eslint-disable-next-line no-underscore-dangle
    __getMerchantType(order) {
      // eslint-disable-next-line no-nested-ternary
      return `${order.merchant.merchant_setting ? (order.merchant.merchant_setting.merchant_type === 'cash' ? 'C' : '') : 'WC'}`
    },
  },
})
Vue.use(laravel_acl, router)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(TreeViewPlugin)

library.add(faXmark)

// Sweet Alert
Vue.use(VueSweetalert2)

Vue.use(LetItSnow)
Vue.use(VueFormulate)
Vue.use(VueIdentifyNetwork)

Vue.use(mdiVue, {
  icons: mdijs,
})

// Excel Download
Vue.use('downloadExcel', JsonExcel)
// Excel
Vue.use(VueExcelEditor)

// Validate util
Vue.prototype.$validate = validate
// Vue.use(il18n)

// Composition API
Vue.use(VueCompositionAPI)

// Axios
const csrfToken = document.head.querySelector('meta[name="csrf-token"]')
// axios.defaults.withCredentials = true
// axios.defaults.baseURL = 'http://curfox.localhost:8000/api/'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
Vue.use(VueAxios, axios)

// vue Cookies
Vue.use(VueCookies)

// vue component registerd globally
Vue.component('ReportTableButtons', ReportTableButtonsVue)
Vue.component('TableDataFetching', TableDataFetching)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
authController.init(router, store)

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
