import Repository from '@/libs/axios'

const bannerResource = '/admin/notifications'
const bannerNoAuthResource = '/notifications/active-banners-no-auth'

const defaultPagination = 10

export default {
  getAllBannerList(filterQuery, pageNumber = 1, pagination = defaultPagination) {
    return Repository.get(`${bannerResource}/banner?${filterQuery}&paginate=${pagination}&page=${pageNumber}`)
  },

  getAllBannerListNoPagination(filterQuery, sort) {
    return Repository.get(`${bannerResource}/banner?sort=${sort}&${filterQuery}&noPagination`)
  },
  getBannerById(id) {
    return Repository.get(`${bannerResource}/banner/get-banner-by-id/${id}`)
  },
  createBanner(payload) {
    return Repository.post(`${bannerResource}/banner`, payload)
  },
  updateBanner(id, payload) {
    return Repository.put(`${bannerResource}/banner/${id}`, payload)
  },
  deleteBanner(id) {
    return Repository.delete(`${bannerResource}/banner/${id}`)
  },
  getActiveBannerAuth() {
    return Repository.get(`${bannerResource}/active-banners-auth`)
  },
  getActiveBannerNoAuth() {
    return Repository.get(`${bannerNoAuthResource}`)
  },
}
