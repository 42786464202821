import Repository from '@/libs/axios'

const emailConfigResource = '/admin/settings/email'

export default {
  getEmailConfigResource() {
    return Repository.get(`${emailConfigResource}`)
  },
  updateEmailConfigResource(payload) {
    return Repository.put(`${emailConfigResource}`, payload)
  },
  sendTestEmail(payload) {
    return Repository.post(`${emailConfigResource}/test-email`, payload)
  },
}
