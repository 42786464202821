import Repository from '@/libs/axios'

const approvalSettingsResource = '/admin/approvals/request'

export default {
  getProfileUpdateSettingTypes(groupId) {
    return Repository.get(`${approvalSettingsResource}/get-types?group_id=${groupId}`)
  },
  statusAction(payload) {
    return Repository.post(`${approvalSettingsResource}/types-status/update`, payload)
  },
  changeProfileUpdateStatus(payload) {
    return Repository.post(`${approvalSettingsResource}/group-status/update`, payload)
  },
  getGroupStatus(groupId) {
    return Repository.get(`${approvalSettingsResource}/get-group/${groupId}`)
  },
}
