// eslint-disable-next-line import/no-cycle
import store from '@/store'

const getInitialPath = () => {
  /* eslint-disable no-nested-ternary */
  const dashboardPermissions = store ? store.getters.permissions.filter(permission => (permission === 'dashboard' || permission === 'warehouse-dashboard')).length > 0 : false
  const allOrderPermissions = store ? store.getters.permissions.filter(permission => (permission === 'view-orders')).length > 0 : false
  return dashboardPermissions ? '/' : (allOrderPermissions ? '/operations/all-orders' : '/system-settings/business-setting')
}

export default getInitialPath
