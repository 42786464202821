import Repository from '@/libs/axios'

const financeSettingsResource = '/admin/settings/finance'

export default {
  getFinanceSettingsResource() {
    return Repository.get(`${financeSettingsResource}`)
  },
  updateFinanceSettingsResource(payload) {
    return Repository.put(`${financeSettingsResource}`, payload)
  },
}
