import Repository from '@/libs/axios'

const taxSettingsResource = '/admin/settings/tax'
const taxTypesResource = '/admin/tax'
const taxStatusUpdate = '/admin/tax/update-status'

export default {
  getTaxSetting() {
    return Repository.get(`${taxSettingsResource}`)
  },
  updateTaxSetting(payload) {
    return Repository.put(`${taxSettingsResource}`, payload)
  },
  saveTaxes(payload) {
    return Repository.post(`${taxTypesResource}`, payload)
  },
  updateTaxes(payload) {
    return Repository.put(`${taxStatusUpdate}`, payload)
  },
  getTaxes() {
    return Repository.get(`${taxTypesResource}`)
  },
}
