import Repository from '@/libs/axios'

const trackingSettingsPublicResource = '/settings/tracking'
const trackingSettingsLogoResource = '/admin/settings/general/tracking-image'
const trackingSettingsAdvanceResource = '/admin/order/tracking-info/advance-tracking-details'
const trackingSettingsValueResource = '/admin/settings/general/advance-tracking-details'

const defaultPagination = 20
export default {
  getCurrentLogo() {
    return Repository.get(`${trackingSettingsPublicResource}`)
  },
  saveBackgroundImage(payload) {
    const formData = new FormData()
    formData.append('file', payload)
    return Repository.post(`${trackingSettingsLogoResource}`, formData)
  },
  getAdvanceTrackingList(pageNumber, pagination = defaultPagination) {
    return Repository.get(`${trackingSettingsAdvanceResource}?paginate=${pagination}&page=${pageNumber}&sort=-id`)
  },
  setActiveStatus(id, payload) {
    return Repository.put(`${trackingSettingsAdvanceResource}/${id}`, payload)
  },
  updateAdvanceTrackingValue(payload) {
    return Repository.put(`${trackingSettingsValueResource}`, payload)
  },
}
