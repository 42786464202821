import Repository from '@/libs/axios'

const layoutSettingResource = '/admin/settings/layout'

export default {
  getWaybillLayout() {
    return Repository.get(`${layoutSettingResource}/waybill`)
  },
  getActiveWaybillLayout() {
    return Repository.get(`${layoutSettingResource}/waybill/active`)
  },
  getWaybillLayoutDetail(id) {
    return Repository.get(`${layoutSettingResource}/waybill/${id}`)
  },
  updateWaybillLayout(payload) {
    return Repository.put(`${layoutSettingResource}/waybill`, payload)
  },
  updateWaybillLayoutStatus(payload) {
    return Repository.put(`${layoutSettingResource}/waybill/update`, payload)
  },
}
