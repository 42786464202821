import Repository from '@/libs/axios'

const walkingCustomer = '/admin/walking-customer'

const defaultPagination = 10

export default {
  getWalkingCustomerList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort = 'name') {
    return Repository.get(`${walkingCustomer}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}`)
  },
  getWalkingCustomerDropdownList() {
    return Repository.get('admin/walking/list/walkingcustomerdropdown')
  },
  getWalkingCustomerListNoPagination(filterQuery, sort) {
    return Repository.get(`${walkingCustomer}?&sort=${sort}&${filterQuery}&noPagination`)
  },
  getWalkingCustomerById(id) {
    return Repository.get(`${walkingCustomer}/${id}`)
  },
  createWalkingCustomer(payload) {
    return Repository.post(`${walkingCustomer}`, payload)
  },
  updateWalkingCustomer(id, payload) {
    return Repository.put(`${walkingCustomer}/${id}`, payload)
  },
}
