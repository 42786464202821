import Repository from '@/libs/axios'
import OperationsRepository from '@/libs/customAxios/operationAxios'
import ListsRepository from '@/libs/customAxios/listsAxios'
import DashboardRepository from '@/libs/customAxios/dashboardAxios'

const orderResource = '/admin/order'

const defaultPagination = 10
export default {
  getFullStatusList() {
    return Repository.get('/admin/status?noPagination')
  },
  updateStatusId(id) {
    return Repository.get(`/admin/status/${id}`)
  },
  getStatusManaegeList(pageNumber = 1, pagination = defaultPagination, filterQuery) {
    return Repository.get(`/admin/status?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  updateStatusIdupdate(id, payload) {
    return Repository.put(`/admin/status/${id}`, payload)
  },
  getStatusList(type) {
    return Repository.get(`/admin/status/${type}`)
  },
  createSingleOrder(payload) {
    return Repository.post('/admin/order/single', payload)
  },
  createSingleOrderWalkingCustomer(payload) {
    return Repository.post('/admin/order/walking/single', payload)
  },
  createBulkOrder(payload) {
    return Repository.post('/admin/order/bulk', payload)
  },
  createBulkExcelOrder(payload) {
    return Repository.post('/admin/order/bulk-excel', payload)
  },
  createBulkOrderWalkingCustomer(payload) {
    return Repository.post('/admin/order/walking/bulk', payload)
  },
  getOrderListByKey(key, pageNumber = 1, pagination = defaultPagination, filterQuery) {
    return ListsRepository.get(`/admin/order/operation?status_key=${key}&paginate=${pagination}&page=${pageNumber}&${filterQuery}&sort=-id`)
  },
  getOrderListByKeyNoPagination(key, filterQuery) {
    return ListsRepository.get(`/admin/order/operation?status_key=${key}&${filterQuery}&sort=-id&noPagination`)
  },
  getOrderList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return ListsRepository.get(`/admin/order/?${filterQuery}&page=${pageNumber}&paginate=${pagination}&sort=-id&${sort}`)
  },
  getOrderListNoPagination(filterQuery) {
    return ListsRepository.get(`/admin/order/?${filterQuery}&noPagination`)
  },
  getOrderListQueue(filterQuery) {
    return Repository.post(`${orderResource}/queue/all-order-excel-download?${filterQuery}`)
  },
  getOrderListFiltered(pageNumber = 1, filterQuery) {
    return Repository.get(`/admin/order?${filterQuery}&page=${pageNumber}`)
  },
  getOrderDetails(waybillNumber) {
    return OperationsRepository.get(`/admin/order/waybill/${waybillNumber}`)
  },
  fetchOrderData(waybillNumber) {
    return OperationsRepository.get(`/admin/order/waybill/fetch-data/${waybillNumber}`)
  },
  getOrderDetailsById(id) {
    return Repository.get(`/admin/order/${id}`)
  },
  getOrderSummery() {
    return DashboardRepository.get('/admin/order/summery')
  },
  getOrderFlagDetailsById(id) {
    return Repository.get(`/admin/order/${id}/flag`)
  },
  getOrderTimeLine(id) {
    return Repository.get(`/admin/order/${id}/time-line`)
  },
  getOrderFinanceTimeLine(id) {
    return Repository.get(`/admin/order/${id}/finance-time-line`)
  },
  getOrderInvoiceTimeLine(id) {
    return Repository.get(`/admin/order/${id}/invoice-time-line`)
  },
  getOrderReversedDetails(id) {
    return Repository.get(`/admin/order/${id}/reversal/time-line`)
  },
  updateOrderStatus(payload) {
    return OperationsRepository.post('/admin/order/status-update', payload)
  },
  reverseOrderStatus(payload) {
    return OperationsRepository.post('/admin/order/status-reverse', payload)
  },
  updateOrderDetails(id, payload) {
    return Repository.put(`/admin/order/${id}`, payload)
  },
  getPODOrdersByWaybill(waybill) {
    return Repository.get(`/admin/order/${waybill}/print-pod`)
  },
  getPODOrdersByWaybills(waybills) {
    return Repository.get('/admin/order/print-pod-bulk', {
      params: {
        waybill_number: waybills,
      },
    })
  },
  calculateDeliveryCharge(payload) {
    return Repository.get('/admin/order/delivery-charge', {
      params: payload,
    })
  },
  updateRiderSortIndex(id, payload) {
    return Repository.put(`/rider/order/${id}/sort-index`, payload)
  },
}
