<template>
  <div @click="generatePDF">
    <div class="d-flex">
      <img
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiM2NjY2NjYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS1maWxlLXRleHQiPjxwYXRoIGQ9Ik0xNC41IDJINmEyIDIgMCAwIDAtMiAydjE2YTIgMiAwIDAgMCAyIDJoMTJhMiAyIDAgMCAwIDItMlY3LjVMMTQuNSAyeiIvPjxwb2x5bGluZSBwb2ludHM9IjE0IDIgMTQgOCAyMCA4Ii8+PGxpbmUgeDE9IjE2IiB4Mj0iOCIgeTE9IjEzIiB5Mj0iMTMiLz48bGluZSB4MT0iMTYiIHgyPSI4IiB5MT0iMTciIHkyPSIxNyIvPjxsaW5lIHgxPSIxMCIgeDI9IjgiIHkxPSI5IiB5Mj0iOSIvPjwvc3ZnPg=="
      >
      <div class="name">
        PDF
      </div>
    </div>
  </div>
</template>
<script>
import {
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import autoTable from 'jspdf-autotable'
import Ripple from 'vue-ripple-directive'
import JsPDF from 'jspdf'

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    tableFields: {
      type: Array,
      default: () => {},
    },
    tableData: {
      type: Array,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    generatePDF() {
      const doc = new JsPDF({
        orientation: 'landscape',
        format: 'a3',
      })
      doc.text(this.title, 14, 10)
      autoTable(doc, { html: '#my-table' })
      autoTable(doc, {
        head: [this.tableFields],
        body: this.tableData,
        columnStyles: {
          // 3: { align: 'left' },
          0: { cellWidth: 23 },
          1: { cellWidth: 23 },
          2: { cellWidth: 23 },
          3: { cellWidth: 23 },
          4: { cellWidth: 23 },
          5: { cellWidth: 23 },
          6: { cellWidth: 23 },
          7: { cellWidth: 23 },
          8: { cellWidth: 23 },
          9: { cellWidth: 24 },
          10: { cellWidth: 22, halign: 'right' },
          11: { cellWidth: 22, halign: 'right' },
          12: { cellWidth: 22, halign: 'right' },
          13: { cellWidth: 22, halign: 'right' },
          14: { cellWidth: 23 },
          15: { cellWidth: 23 },
          16: { cellWidth: 28 },
        },
      })
      doc.save('All Orders.pdf')
    },
  },
}

</script>
<style scoped>
.name {
  font-weight: bolder;
  margin-left: 5px;
  margin-top: 3px;
  color: #666666;
}
</style>
