import Repository from '@/libs/axios'

const packageResource = '/packagesubscription'

export default {
  packageDetails() {
    return Repository.get(`${packageResource}/tenant/packages?filter[is_active]=1&filter[visibility]=public`)
  },
  packageSubscribe(payload) {
    return Repository.post(`${packageResource}/tenant/subscribe`, payload)
  },
  packageDetailsNoPagination() {
    return Repository.get(`${packageResource}/tenant/packages?filter[is_active]=1&filter[visibility]=public&noPagination`)
  },
}
