import Cookies from 'js-cookie'
import axios from '@/libs/axios'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import router from '@/router/index'
// eslint-disable-next-line import/no-cycle
import redirectToValidPage from '@/router/redirectToValidPage'

const StaffAuthRepository = RepositoryFactory.get('staffauth')
const OrderRepository = RepositoryFactory.get('order')
const FinanceRepository = RepositoryFactory.get('finance')
const CacheRepository = RepositoryFactory.get('cache')
const ResourceRepository = RepositoryFactory.get('resource')

export default {
  state: {
    current_user_data: null,
    current_user_permission: null,
    order_status_list: null,
  },
  mutations: {
    CLEAR_CURRENT_USER: state => {
      state.current_user_data = undefined
      state.current_user_permission = undefined
      Cookies.remove('current_user_token')
    },
    SET_CURRENT_USER_PERMISSION: (state, value) => {
      state.current_user_permission = value
      Cookies.set('current_user_permission', value)
    },

    SET_CURRENT_USER: (state, value) => {
      state.current_user_data = value.user
      Cookies.set('current_user_token', value.token)
      axios.defaults.headers.common.Authorization = `Bearer ${value.token}`
    },

    SET_ORDER_STATUS_LIST: (state, value) => {
      state.order_status_list = value
      Cookies.set('order_status_list', value)
    },
  },
  getters: {
    isLoggedIn: state => state.current_user_data !== null,
    currentUser: state => state.current_user_data,
    permissions: state => state.current_user_permission,
    statusList: state => state.order_status_list,
  },
  actions: {
    setCurrentUser: ({ commit }, payload) => { commit('SET_CURRENT_USER', payload) },

    // eslint-disable-next-line no-unused-vars
    async afterLogin({ commit }, fromLogin) {
      if (!fromLogin) router.replace(redirectToValidPage())
    },
    async autoLogin({ commit }) {
      try {
        // eslint-disable-next-line camelcase
        const cacheUserToken = Cookies.get('current_user_token')

        if (!this.getters.isLoggedIn && cacheUserToken) {
          let cacheUserData
          if (localStorage.getItem('current_user')) {
            cacheUserData = JSON.parse(localStorage.getItem('current_user'))
          } else {
            cacheUserData = (await StaffAuthRepository.getCurrentUser()).data.data
            localStorage.setItem('current_user', JSON.stringify(cacheUserData))
          }
          commit('SET_CURRENT_USER', {
            user: cacheUserData,
            token: cacheUserToken,
          })

          await this.dispatch('dataLoad')
          await this.dispatch('afterLogin', true)
        }
      } catch (e) {
        await this.dispatch('logout')
      }
    },

    async login({ commit }, payload) {
      const loginData = (await StaffAuthRepository.staffLogin(payload)).data
      localStorage.setItem('current_user', JSON.stringify(loginData.user))
      commit('SET_CURRENT_USER', loginData)
      await this.dispatch('dataLoad')
      await this.dispatch('afterLogin')
    },

    async logout({ commit }) {
      // Cookies.remove('current_user_token')
      commit('CLEAR_CURRENT_USER')
      // await StaffAuthRepository.staffLogout()
      window.location = '/login'
    },

    async dataLoad({ commit }) {
      const { data } = (await CacheRepository.getCacheRefreshKeys()).data
      data.forEach(item => {
        localStorage.removeItem(item.cache_key)
      })

      let permissions

      if (localStorage.getItem('current_permissions')) {
        permissions = JSON.parse(localStorage.getItem('current_permissions'))
      } else {
        permissions = (await StaffAuthRepository.getPermissions()).data.data
        localStorage.setItem('current_permissions', JSON.stringify(permissions))
      }
      commit('SET_CURRENT_USER_PERMISSION', permissions.map(x => x.name))

      let statuses
      if (localStorage.getItem('order_statuses')) {
        statuses = JSON.parse(localStorage.getItem('order_statuses'))
      } else {
        statuses = (await OrderRepository.getFullStatusList()).data.data
        localStorage.setItem('order_statuses', JSON.stringify(statuses))
      }
      commit('SET_ORDER_STATUS_LIST', statuses)

      let financeStatuses
      if (localStorage.getItem('finance_statuses')) {
        financeStatuses = JSON.parse(localStorage.getItem('finance_statuses'))
      } else {
        financeStatuses = (await FinanceRepository.getFinanceStatusList()).data.data
        localStorage.setItem('finance_statuses', JSON.stringify(financeStatuses))
      }

      let warehouses
      if (localStorage.getItem('assigned_warehouse_list')) {
        warehouses = JSON.parse(localStorage.getItem('assigned_warehouse_list'))
      } else {
        warehouses = (await ResourceRepository.getAssignedWarehousesNoPagination()).data.data
        localStorage.setItem('assigned_warehouse_list', JSON.stringify(warehouses))
      }

      let cityList
      if (localStorage.getItem('city_list')) {
        cityList = JSON.parse(localStorage.getItem('city_list'))
      } else {
        cityList = (await ResourceRepository.getCityDropdownListNoFilter()).data.data
        localStorage.setItem('city_list', JSON.stringify(cityList))
      }
    },
  },
}
