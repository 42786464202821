import Repository from '@/libs/axios'

const defaultPagination = 10

const rateCardResource = '/admin/rate-card'
const rateCardWalikingResource = '/admin/rate-card/walking'
const rateCardRiderResource = '/admin/rate-card/rider'
const rateCardAgentResource = '/admin/rate-card/agent'
const rateCardResourceExtract = '/admin/zone/zone-extract'
const rateCardDetails = '/admin/rate-card'
const rateCardMerchantDelete = '/admin/rate-card'
const rateCardAgentDelete = '/admin/rate-card/agent'
const rateCardRiderDelete = '/admin/rate-card/rider'
const rateCardWalkingCustomerDelete = '/admin/rate-card/walking'

export default {
  getMerchantRateCardList(filterQuery, pageNumber = 1, pagination = defaultPagination) {
    return Repository.get(`${rateCardResource}?${filterQuery}&paginate=${pagination}&page=${pageNumber}`)
  },
  getMerchantRateCardTableView(id) {
    return Repository.get(`${rateCardResource}/${id}/get-table-view`)
  },
  getRiderRateCardList(filterQuery, pageNumber = 1, pagination = defaultPagination) {
    return Repository.get(`${rateCardRiderResource}?${filterQuery}&paginate=${pagination}&page=${pageNumber}`)
  },
  getRiderRateCardTableView(id) {
    return Repository.get(`${rateCardRiderResource}/${id}/get-table-view`)
  },
  getAgentRateCardList(filterQuery, pageNumber = 1, pagination = defaultPagination) {
    return Repository.get(`${rateCardAgentResource}?${filterQuery}&paginate=${pagination}&page=${pageNumber}`)
  },
  getAgentRateCardTableView(id) {
    return Repository.get(`${rateCardAgentResource}/${id}/get-table-view`)
  },
  getWalkingCustomerRateCardList(filterQuery, pageNumber = 1, pagination = defaultPagination) {
    return Repository.get(`${rateCardWalikingResource}?${filterQuery}&paginate=${pagination}&page=${pageNumber}`)
  },
  getWalkingCustomerRateCardTableView(id) {
    return Repository.get(`${rateCardWalikingResource}/${id}/get-table-view`)
  },
  getRateCardIDList(id) {
    return Repository.get(`${rateCardResourceExtract}/${id}`)
  },
  createRateCard(payload) {
    return Repository.post(`${rateCardResource}`, payload)
  },
  createWalkingCustomerRateCard(payload) {
    return Repository.post(`${rateCardWalikingResource}`, payload)
  },
  createAgentRateCard(payload) {
    return Repository.post(`${rateCardAgentResource}`, payload)
  },
  createRiderRateCard(payload) {
    return Repository.post(`${rateCardRiderResource}`, payload)
  },
  getRateCardMerchantList(id, cloneUserActive = null) {
    const params = cloneUserActive !== null ? { user_clone_active: cloneUserActive } : {}
    return Repository.get(`${rateCardResource}/${id}/merchant-list`, { params })
  },
  getAssignedAgentRateCardList(id, cloneUserActive = null) {
    const params = cloneUserActive !== null ? { user_clone_active: cloneUserActive } : {}
    return Repository.get(`${rateCardAgentResource}/${id}/agent-list`, { params })
  },
  getRiderListRateCard(id, cloneUserActive = null) {
    const params = cloneUserActive !== null ? { user_clone_active: cloneUserActive } : {}
    return Repository.get(`${rateCardRiderResource}/${id}/rider-list`, { params })
  },
  assignMerchantList(id, payload) {
    return Repository.put(`${rateCardResource}/${id}/assign-rate-card`, payload)
  },
  assignAgentList(id, payload) {
    return Repository.put(`${rateCardAgentResource}/${id}/assign-rate-card`, payload)
  },
  assignRiderList(id, payload) {
    return Repository.put(`${rateCardRiderResource}/${id}/assign-rate-card`, payload)
  },
  getRateCardDetails(id) {
    return Repository.get(`${rateCardDetails}/${id}`)
  },
  getAgentRateCardDetails(id) {
    return Repository.get(`${rateCardAgentResource}/${id}`)
  },
  getRiderRateCardDetails(id) {
    return Repository.get(`${rateCardRiderResource}/${id}`)
  },
  getWalkingCustomerRateCardDetails(id) {
    return Repository.get(`${rateCardWalikingResource}/${id}`)
  },
  setDefaultWalkingCustomerRateCard(id) {
    return Repository.get(`${rateCardWalikingResource}/${id}/change-status`)
  },
  deleteMerchantRateCard(id) {
    return Repository.delete(`${rateCardMerchantDelete}/${id}`)
  },
  deleteAgentRateCard(id) {
    return Repository.delete(`${rateCardAgentDelete}/${id}`)
  },
  deleteRiderRateCard(id) {
    return Repository.delete(`${rateCardRiderDelete}/${id}`)
  },
  deleteWalkingCustomerRateCard(id) {
    return Repository.delete(`${rateCardWalkingCustomerDelete}/${id}`)
  },
}
