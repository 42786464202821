import Repository from '@/libs/axios'

const quickbookAuthResource = '/admin/quickbook/auth'
const quickbookResource = '/admin/quickbook'

export default {
  getAuthUrl() {
    return Repository.get(`${quickbookAuthResource}/url`)
  },
  generateToken(payload) {
    return Repository.post(`${quickbookAuthResource}/generate-token`, payload)
  },
  getConnectionStatus() {
    return Repository.get(`${quickbookResource}/connection-status`)
  },
  activateMerchant(payload) {
    return Repository.put(`${quickbookResource}/activate/merchant`, payload)
  },
  activateWarehouse(payload) {
    return Repository.put(`${quickbookResource}/activate/warehouse`, payload)
  },
  syncMerchants() {
    return Repository.post(`${quickbookResource}/sync/merchant`)
  },
  syncWarehouses() {
    return Repository.post(`${quickbookResource}/sync/warehouse`)
  },
  syncDeposits() {
    return Repository.post(`${quickbookResource}/sync/deposit`)
  },
  syncInvoices() {
    return Repository.post(`${quickbookResource}/sync/invoice`)
  },
  syncAccountsAndServices() {
    return Repository.post(`${quickbookResource}/sync/account-and-service`)
  },
  disconnect() {
    return Repository.delete(`${quickbookResource}/disconnect`)
  },
}
