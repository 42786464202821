/* eslint-disable */
import store from '../../store'

const laravel_acl = {}
laravel_acl.install = function (Vue, router) {

  // Run on install
  const finalRoutes = []
  const routes = router.options.routes.map(route => {

    if (route.children) {
      for (const paths of route.children) {
        finalRoutes.push(paths)
      }
    }
    return route
  })
  for (const paths of routes) {
    finalRoutes.push(paths)
  }

  function commentNode (el, vnode) {
    const comment = document.createComment(' ')

    Object.defineProperty(comment, 'setAttribute', {
      value: () => undefined
    })

    vnode.text = ' '
    vnode.elm = comment
    vnode.isComment = true
    vnode.tag = undefined

    vnode.data = vnode.data || {}
    vnode.data.directives = undefined

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el)
    }
  }


  Vue.directive('can', function (el, binding, vnode) {
    const permissions = store.getters.permissions
    let final = false
    if ((typeof binding.value) === 'number') {
      binding.value = binding.expression
    }
    if (binding.arg) {
      switch (binding.arg.toLowerCase()) {
        case 'any':
          for (const permissionName of binding.value) {
            if (permissions.find(permission => permission === permissionName) !== undefined) {
              final = true
              break
            }
          }
          break
        case 'all':
          final = true
          for (const permissionName of binding.value) {
            if (permissions.find(permission => permission === permissionName) === undefined) {
              final = false
              break
            }
          }
          break
        case 'not':
          final = (permissions.find(permission => permission === binding.value) === undefined)
          break
        case 'disable':
          binding.modifiers.disable = true
          break
      }

    } else if (permissions.find(permission => permission === binding.value) !== undefined) {
      final = true
    }
    if (binding.modifiers.not) {
      final = !final
    }
    el.disabled = false
    if (!final) {
      if (binding.modifiers.disable) {
        el.disabled = true
      } else {
        commentNode(el, vnode)
      }
    }
  })

  //Permissions

  Vue.prototype.$can = function (permissionName) {
    const permissions = store.getters.permissions
    return permissions.find(permission => permission === permissionName) !== undefined
  }

  Vue.prototype.$canAny = function (permissionArray) {
    const permissions = store.getters.permissions
    for (const permissionName of permissionArray) {
      if (permissions.find(permission => permission === permissionName) !== undefined) {
        return true
      }
    }
    return false
  }

  Vue.prototype.$canAll = function (permissionArray) {
    const permissions = store.getters.permissions
    for (const permissionName of permissionArray) {
      if (permissions.find(permission => permission === permissionName) === undefined) {
        return false
      }
    }
    return true
  }


  //Check

  Vue.prototype.$canGo = function (pathURLArray) {

    const permissions = store.getters.permissions
    for (const pathURL of pathURLArray) {
      const to = finalRoutes.find(path => path.path === pathURL)
      if (!to) continue
      if (!to.meta) return true
      let final = true

      if (permissions && to.meta.permissions) {
        let final_permissions = false
        loop3:for (const permissionName of to.meta.permissions) {
          if (permissions.find(permission => permission === permissionName) !== undefined) {
            final_permissions = true
            break loop3
          }
        }
        final = final &&  final_permissions
      }
      if (final) {
        return true
      }
    }
    return false
  }

  //Router

  router.afterEach((to) => {
    const permissions = store.getters.permissions

    if (permissions && to.meta.permissions) {
      for (const permissionName of to.meta.permissions) {
        if (permissions.find(permission => permission === permissionName) !== undefined) {
          return
        }
      }
      router.push('/')
    }
  })
}

export default laravel_acl
/* eslint-enable */
