// import Repository from '@/libs/axios'
import DashboardRepository from '@/libs/customAxios/dashboardAxios'
// import KPIDashboardRepository from '@/libs/customAxios/kpiAxios'

const orderStatsFinanceResource = '/admin/dashboard/order-statistics'
const financialStatFinanceResource = '/admin/dashboard/financial-statistics'
const orderMonthlyOrdersFinanceResource = '/admin/dashboard/monthly-orders'
const orderMonthlyEarningsFinanceResource = '/admin/dashboard/monthly-earning'
const orderCODReceivableFinanceResource = '/admin/dashboard/cod-receivable'
const orderToBEInvoicesFinanceResource = '/admin/dashboard/to-be-invoice'
const orderDeliveredFailedRatioResource = '/admin/dashboard/delivered-failed'
const orderMerchantOnBoardingFinanceResource = '/admin/dashboard/merchant-on-boarding'
const orderStatusStatisticsFinanceResource = '/admin/dashboard/status-statistics'
const LastMonthsFinanceResource = '/admin/dashboard/last-three-month'
const citywiseStatsResource = '/admin/dashboard/city-wise-stats'
const groupedBarChartResource = '/admin/dashboard/dsr-kpi'

// const defaultPagination = 10
export default {
  getOrderStatsFinance() {
    return DashboardRepository.get(`${orderStatsFinanceResource}`)
  },
  getFinancialStatFinance() {
    return DashboardRepository.get(`${financialStatFinanceResource}`)
  },
  getMonthlyOrdersFinance() {
    return DashboardRepository.get(`${orderMonthlyOrdersFinanceResource}`)
  },
  getMonthlyEarningsFinance() {
    return DashboardRepository.get(`${orderMonthlyEarningsFinanceResource}`)
  },
  getCODRecivableFinance() {
    return DashboardRepository.get(`${orderCODReceivableFinanceResource}`)
  },
  getToBEInvoicesFinance() {
    return DashboardRepository.get(`${orderToBEInvoicesFinanceResource}`)
  },
  getDeliveredFailedData() {
    return DashboardRepository.get(`${orderDeliveredFailedRatioResource}`)
  },
  getMerchantOnBoardingFinance() {
    return DashboardRepository.get(`${orderMerchantOnBoardingFinanceResource}`)
  },
  getStatusStatisticsFinance() {
    return DashboardRepository.get(`${orderStatusStatisticsFinanceResource}`)
  },
  getLastMonthsFinance() {
    return DashboardRepository.get(`${LastMonthsFinanceResource}`)
  },
  getCityWiseStats() {
    return DashboardRepository.get(`${citywiseStatsResource}`)
  },
  getDataForDSRKPI() {
    return DashboardRepository.get(`${groupedBarChartResource}`)
  },
}
