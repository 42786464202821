// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line consistent-return
const redirectToValidPage = () => {
  if (store.getters.permissions.includes('dashboard')) {
    return '/'
  } if (!(store.getters.permissions.includes('dashboard')) && (store.getters.permissions.includes('warehouse-dashboard'))) {
    return '/warehouse'
  } if (!(store.getters.permissions.includes('dashboard')) && !(store.getters.permissions.includes('warehouse-dashboard'))) {
    return '/operations/all-orders'
  }
}

export default redirectToValidPage
