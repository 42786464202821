import Repository from '@/libs/axios'

const statusSettingsResource = '/admin/settings'

export default {
  updateOrderStatuses(id, payload) {
    return Repository.put(`${statusSettingsResource}/order-status/${id}`, payload)
  },
  updateFinanceStatuses(payload) {
    return Repository.put(`${statusSettingsResource}/finance-status`, payload)
  },
}
