import Repository from '@/libs/axios'

const businessSettingsResource = '/admin/settings/business'
const businessSettingsPublicResource = '/settings/business'
const businessSettingsLogoResource = '/admin/settings/business/logo'
const businessSettingsForLoginResource = '/settings/business'
// const defaultPagination = 10
export default {
  getbusinessSettingsResource() {
    return Repository.get(`${businessSettingsResource}`)
  },
  getbusinessPublicSettingsResource() {
    return Repository.get(`${businessSettingsPublicResource}`)
  },
  getbusinessSettingsForLoginResource() {
    return Repository.get(`${businessSettingsForLoginResource}`)
  },
  updatebusinessSettingsResource(payload) {
    return Repository.put(`${businessSettingsResource}`, payload)
  },
  updatebusinessSettingsLogoResource(payload) {
    const formData = new FormData()
    formData.append('file', payload)
    return Repository.post(`${businessSettingsLogoResource}`, formData)
  },
}
