// import Repository from '@/libs/axios'
import DashboardRepository from '@/libs/customAxios/dashboardAxios'

const dashboardResource = '/admin/finance/rider/dashboard'
const agentDashboardResource = '/admin/finance/agency/dashboard'
const branchDashboardResource = '/admin/finance/branch/dashboard'
const merchantDashboardResource = '/admin/finance/merchant/dashboard'

// const defaultPagination = 10
export default {
  // rider
  getAllOrdersStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/all-orders?${filterQuery}`)
  },
  getTotalRiderCommisionStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/total-rider-commision?${filterQuery}`)
  },
  getPendingNotDeliverdStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/pending-not-delivered?${filterQuery}`)
  },
  getPendingDeliverdStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/pending-delivered?${filterQuery}`)
  },
  getPendingDepositStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/pending-deposit?${filterQuery}`)
  },
  getDepositedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/deposited?${filterQuery}`)
  },
  getApprovedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/approved?${filterQuery}`)
  },

  // agent
  getAgentAllOrdersStatsFinance(filterQuery) {
    return DashboardRepository.get(`${agentDashboardResource}/all-orders?${filterQuery}`)
  },
  getAgentTotalAgencyCommisionStatsFinance(filterQuery) {
    return DashboardRepository.get(`${agentDashboardResource}/total-agency-commision?${filterQuery}`)
  },
  getAgentNotRiderAssignStatsFinance(filterQuery) {
    return DashboardRepository.get(`${agentDashboardResource}/not-rider-assign?${filterQuery}`)
  },
  getAgentRiderAssignStatsFinance(filterQuery) {
    return DashboardRepository.get(`${agentDashboardResource}/rider-assign?${filterQuery}`)
  },
  getAgentDeliveryNotDepositedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${agentDashboardResource}/delivery-not-deposited?${filterQuery}`)
  },
  getAgentPendingDepositStatsFinance(filterQuery) {
    return DashboardRepository.get(`${agentDashboardResource}/pending-deposit?${filterQuery}`)
  },
  getAgentDepositedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${agentDashboardResource}/deposited?${filterQuery}`)
  },
  getAgentApprovedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${agentDashboardResource}/approved?${filterQuery}`)
  },

  // branch
  getBranchAllOrdersStatsFinance(filterQuery) {
    return DashboardRepository.get(`${branchDashboardResource}/all-orders?${filterQuery}`)
  },
  getBranchTotalBranchCommisionStatsFinance(filterQuery) {
    return DashboardRepository.get(`${branchDashboardResource}/total-branch-rider-commision?${filterQuery}`)
  },
  getBranchNotRiderAssignStatsFinance(filterQuery) {
    return DashboardRepository.get(`${branchDashboardResource}/not-rider-assign?${filterQuery}`)
  },
  getBranchRiderAssignStatsFinance(filterQuery) {
    return DashboardRepository.get(`${branchDashboardResource}/rider-assign?${filterQuery}`)
  },
  getBranchDeliveryNotDepositedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${branchDashboardResource}/delivery-not-deposited?${filterQuery}`)
  },
  getBranchPendingDepositStatsFinance(filterQuery) {
    return DashboardRepository.get(`${branchDashboardResource}/pending-deposit?${filterQuery}`)
  },
  getBranchDepositedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${branchDashboardResource}/deposited?${filterQuery}`)
  },
  getBranchApprovedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${branchDashboardResource}/approved?${filterQuery}`)
  },

  // merchant
  getMerchantAllOrdersStatsFinance(filterQuery) {
    return DashboardRepository.get(`${merchantDashboardResource}/all-orders?${filterQuery}`)
  },
  getMerchantPendingDeliveryStatsFinance(filterQuery) {
    return DashboardRepository.get(`${merchantDashboardResource}/pending-delivery?${filterQuery}`)
  },
  getMerchantReturnedOrdersStatsFinance(filterQuery) {
    return DashboardRepository.get(`${merchantDashboardResource}/returned-orders?${filterQuery}`)
  },
  getMerchantDeliveredOrdersStatsFinance(filterQuery) {
    return DashboardRepository.get(`${merchantDashboardResource}/delivered-orders?${filterQuery}`)
  },
  getMerchantDeliveryIncomeStatsFinance(filterQuery) {
    return DashboardRepository.get(`${merchantDashboardResource}/delivery-income?${filterQuery}`)
  },
  getMerchantCommisionIncomeStatsFinance(filterQuery) {
    return DashboardRepository.get(`${merchantDashboardResource}/commision-income?${filterQuery}`)
  },
  getMerchantToBeInvoicedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${merchantDashboardResource}/to-be-invoiced?${filterQuery}`)
  },
  getMerchantPendingDepositStatsFinance(filterQuery) {
    return DashboardRepository.get(`${merchantDashboardResource}/pending-deposit?${filterQuery}`)
  },
  getMerchantDepositedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${merchantDashboardResource}/deposited?${filterQuery}`)
  },
  getMerchantApprovedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${merchantDashboardResource}/approved?${filterQuery}`)
  },
}
