import Repository from '@/libs/axios'

const waybillSettingResource = '/admin/waybill-series'
const waybillSeriesAssignmentResource = '/admin/waybill-series-assignment'

const defaultPagination = 20

export default {
  createWaybillSeries(payload) {
    return Repository.post(`${waybillSettingResource}/add-waybill-series`, payload)
  },
  getWaybillSeriesList(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return Repository.get(`${waybillSettingResource}/waybill-series-list?page=${pageNumber}&paginate=${pagination}&${filterQuery}`)
  },
  getWaybillSeriesListNoPagination(filterQuery) {
    return Repository.get(`${waybillSettingResource}?${filterQuery}&noPagination`)
  },
  getWaybillSeriesByID(id) {
    return Repository.get(`${waybillSettingResource}/${id}/waybill-series-details`)
  },
  updateWaybillSeries(id, payload) {
    return Repository.put(`${waybillSettingResource}/${id}/update-waybill-series`, payload)
  },
  updateWaybillSeriesStatus(id, payload) {
    return Repository.put(`${waybillSettingResource}/${id}/update-waybill-status`, payload)
  },
  deleteWaybillSeries(id) {
    return Repository.delete(`${waybillSettingResource}/${id}/delete-waybill-series`)
  },
  assignWaybillSeries(payload) {
    return Repository.post(`${waybillSeriesAssignmentResource}/assign-to-merchant`, payload)
  },
  fetchAssignedWaybillSeries(id) {
    return Repository.get(`${waybillSeriesAssignmentResource}/${id}/get-assigned-waybills`)
  },
  getActiveWaybillSeriesList(isAuto, pageNumber = 1, pagination = defaultPagination) {
    return Repository.get(`${waybillSettingResource}/${isAuto}/active-waybill-series-list?page=${pageNumber}&paginate=${pagination}`)
  },
  getLongestWaybillNumberLength() {
    return Repository.get(`${waybillSettingResource}/get-longest-waybill-number-length`)
  },
  getCustomerTypes() {
    return Repository.get(`${waybillSettingResource}/customer-types`)
  },
}
