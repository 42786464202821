import Vue from 'vue'
import VueRouter from 'vue-router'

// eslint-disable-next-line import/extensions
//  Dashboard

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/dashboard/',
      },
    },
    {
      path: '/warehouse',
      name: 'warehouse-select',
      component: () => import('@/views/warehouseDashboard/selectwarehouse.vue'),
      meta: {
        pageTitle: 'Warehouse Select',
        breadcrumb: [
          {
            text: 'warehouse Select',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/warehouse-dashboard/',
      },
    },
    {
      path: '/warehouse/:id/:name',
      name: 'warehouse-dashboard',
      component: () => import('@/views/warehouseDashboard/WarehouseDashboard.vue'),
      meta: {
        pageTitle: 'Warehouse Dashboard',
        breadcrumb: [
          {
            text: 'Warehouse Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/all-orders',
      name: 'all-orders',
      component: () => import('@/views/AllOrders.vue'),
      meta: {
        pageTitle: 'All Orders',
        breadcrumb: [
          {
            text: 'All Orders',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/all-orders/',
      },
    },
    {
      path: '/operations/orders/create',
      name: 'order-create',
      component: () => import('@/views/order/create/OrderCreateHome.vue'),
      meta: {
        pageTitle: 'Order Create',
        breadcrumb: [
          {
            text: 'Create',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/order-create/',
      },
    },
    {
      path: '/operations/main-operation',
      name: 'main-operation',
      component: () => import('@/views/order/MainOperation.vue'),
      meta: {
        pageTitle: 'Main Operation',
        breadcrumb: [
          {
            text: 'Main Operation',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/operations-2/#0-toc-title',
      },
    },
    {
      path: '/operations/main-operation/:name',
      name: 'Selected-main-operation',
      component: () => import('@/views/order/SelectedMainOperation.vue'),
      meta: {
        pageTitle: 'Main Operation',
        breadcrumb: [
          {
            text: 'Main Operation',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/operations-2/#0-toc-title',
      },
    },
    {
      path: '/operations/warehouse-operation',
      name: 'warehouse-operation',
      component: () => import('@/views/order/WarehouseOperation.vue'),
      meta: {
        pageTitle: 'Warehouse Operation',
        breadcrumb: [
          {
            text: 'Warehouse Operation',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/operations-2/#2-toc-title',
      },
    },
    {
      path: '/operations/warehouse-operation/:name',
      name: 'Selected-warehouse-operation',
      component: () => import('@/views/order/SelectedWarehouseOperation.vue'),
      meta: {
        pageTitle: 'Warehouse Operation',
        breadcrumb: [
          {
            text: 'Warehouse Operation',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/operations-2/#2-toc-title',
      },
    },
    {
      path: '/operations/pickup-operation',
      name: 'pickup-operation',
      component: () => import('@/views/order/PickupOperation.vue'),
      meta: {
        pageTitle: 'Pickup Operation',
        breadcrumb: [
          {
            text: 'Pickup Operation',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/operations-2/#1-toc-title',
      },
    },
    {
      path: '/operations/pickup-operation/:name',
      name: 'Selected-pickup-operation',
      component: () => import('@/views/order/SelectedPickupOperation.vue'),
      meta: {
        pageTitle: 'Pickup Operation',
        breadcrumb: [
          {
            text: 'Pickup Operation',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/operations-2/#1-toc-title',
      },
    },
    {
      path: '/print-pod',
      name: 'print-pod',
      component: () => import('@/components/print/Dispatch.vue'),
      meta: {
        pageTitle: 'Print POD',
        breadcrumb: [
          {
            text: 'Print POD',
            active: true,
          },
        ],
        contentRenderer: 'pdf',
      },
    },
    {
      path: '/waybill-customizer/:id',
      name: 'waybill-customizer',
      component: () => import('@/views/waybill-customizer/WaybillCustomizer.vue'),
      meta: {
        pageTitle: 'Customizer',
        breadcrumb: [
          {
            text: 'Waybill Customizer',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/rider-overview',
      name: 'rider-overview',
      component: () => import('@/components/FinanceModuleForms/FinanceOverview/RiderOverview.vue'),
      meta: {
        pageTitle: 'Rider',
        breadcrumb: [
          {
            text: 'Rider Overview',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/overview/#1-toc-title',
      },
    },
    {
      path: '/operations/rider-finance',
      name: 'rider-finance',
      component: () => import('@/views/finance/FinanceModule.vue'),
      meta: {
        pageTitle: 'Rider Deposits',
        breadcrumb: [
          {
            text: 'Rider Finance',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/finance-deposits/#0-toc-title',
      },
    },
    {
      path: '/operations/rider-commission',
      name: 'rider-commission',
      component: () => import('@/views/finance/RiderCommission.vue'),
      meta: {
        pageTitle: 'Rider Commission Invoices',
        breadcrumb: [
          {
            text: 'Rider Commission Finance',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/finance-deposits/#0-toc-title',
      },
    },
    {
      path: '/operations/agency-commission',
      name: 'agency-commission',
      component: () => import('@/views/finance/AgentCommission.vue'),
      meta: {
        pageTitle: 'Agent Commission Invoices',
        breadcrumb: [
          {
            text: 'Agent Commission Finance',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/finance-deposits/#0-toc-title',
      },
    },
    {
      path: '/operations/add-new-rider-deposit',
      name: 'add-new-rider-deposit',
      component: () => import('@/components/FinanceModuleForms/AddNewDeposit.vue'),
      meta: {
        pageTitle: 'Add New Deposit',
        breadcrumb: [
          {
            text: 'Rider Finance',
            to: { name: 'rider-finance' },
          },
          {
            text: 'Add New Deposit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/add-new-rider-commission',
      name: 'add-new-rider-commission',
      component: () => import('@/components/FinanceModuleForms/AddNewCommissionInvoice.vue'),
      meta: {
        pageTitle: 'Add New Commission Invoice',
        breadcrumb: [
          {
            text: 'Rider Commission Finance',
            to: { name: 'rider-commission' },
          },
          {
            text: 'Add New Commission Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/add-new-agency-commission',
      name: 'add-new-agency-commission',
      component: () => import('@/components/FinanceModuleForms/AddNewAgentCommissionInvoice.vue'),
      meta: {
        pageTitle: 'Add New Commission Invoice',
        breadcrumb: [
          {
            text: 'Agent Commission Finance',
            to: { name: 'agency-commission' },
          },
          {
            text: 'Add New Commission Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/rider-deposit-orders/:id',
      name: 'rider-deposit-orders',
      component: () => import('@/components/FinanceModuleForms/ActionForms/OrderList.vue'),
      meta: {
        pageTitle: 'Rider Orders',
        breadcrumb: [
          {
            text: 'Rider Finance',
            to: { name: 'rider-finance' },
          },
          {
            text: 'Rider Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/rider-commission-deposits/:id',
      name: 'rider-commission-deposits',
      component: () => import('@/components/FinanceModuleForms/ActionForms/DepositList.vue'),
      meta: {
        pageTitle: 'Rider Deposits',
        breadcrumb: [
          {
            text: 'Rider Commission Finance',
            to: { name: 'rider-commission' },
          },
          {
            text: 'Rider Deposits',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/agency-commission-deposits/:id',
      name: 'agency-commission-deposits',
      component: () => import('@/components/FinanceModuleForms/ActionAgentForms/DepositList.vue'),
      meta: {
        pageTitle: 'Agent Deposits',
        breadcrumb: [
          {
            text: 'Agent Commission Finance',
            to: { name: 'agency-commission' },
          },
          {
            text: 'Agent Deposits',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/agent-overview',
      name: 'agent-overview',
      component: () => import('@/components/FinanceModuleForms/FinanceOverview/AgentOverview.vue'),
      meta: {
        pageTitle: 'Agent',
        breadcrumb: [
          {
            text: 'Agent Overview',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/overview/#3-toc-title',
      },
    },
    {
      path: '/operations/agent-finance',
      name: 'agent-finance',
      component: () => import('@/views/finance/AgentFinance.vue'),
      meta: {
        pageTitle: 'Agent Deposits',
        breadcrumb: [
          {
            text: 'Agent Finance',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/finance-deposits/#2-toc-title',
      },
    },
    {
      path: '/operations/add-new-agent-deposit',
      name: 'add-new-agent-deposit',
      component: () => import('@/components/FinanceModuleForms/AddNewAgentDeposit.vue'),
      meta: {
        pageTitle: 'Add New Deposit',
        breadcrumb: [
          {
            text: 'Agent Finance',
            to: { name: 'agent-finance' },
          },
          {
            text: 'Add New Deposit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/agent-deposit-orders/:id',
      name: 'agent-deposit-orders',
      component: () => import('@/components/FinanceModuleForms/ActionAgentForms/OrderList.vue'),
      meta: {
        pageTitle: 'Agent Orders',
        breadcrumb: [
          {
            text: 'Agent Finance',
            to: { name: 'agent-finance' },
          },
          {
            text: 'Agent Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/branch-branch',
      name: 'branch-overview',
      component: () => import('@/components/FinanceModuleForms/FinanceOverview/BranchOverview.vue'),
      meta: {
        pageTitle: 'Branch',
        breadcrumb: [
          {
            text: 'Branch Overview',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/overview/#2-toc-title',
      },
    },
    {
      path: '/operations/branch-finance',
      name: 'branch-finance',
      component: () => import('@/views/finance/BranchFinance.vue'),
      meta: {
        pageTitle: 'Branch Deposits',
        breadcrumb: [
          {
            text: 'Branch Finance',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/finance-deposits/#1-toc-title',
      },
    },
    {
      path: '/operations/add-new-branch-deposit',
      name: 'add-new-branch-deposit',
      component: () => import('@/components/FinanceModuleForms/AddNewBranchDeposit.vue'),
      meta: {
        pageTitle: 'Add New Deposit',
        breadcrumb: [
          {
            text: 'Branch Finance',
            to: { name: 'branch-finance' },
          },
          {
            text: 'Add New Deposit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/branch-deposit-orders/:id',
      name: 'branch-deposit-orders',
      component: () => import('@/components/FinanceModuleForms/ActionBranchForms/OrderList.vue'),
      meta: {
        pageTitle: 'Branch Orders',
        breadcrumb: [
          {
            text: 'Branch Finance',
            to: { name: 'branch-finance' },
          },
          {
            text: 'Branch Orders',
            active: true,
          },
        ],
      },
    },
    // Add expences
    {
      path: '/operations/branch-deposit-orders-expenses/:id',
      name: 'branch-deposit-expenses',
      component: () => import('@/components/FinanceModuleForms/ExpencesList.vue'),
      meta: {
        pageTitle: 'Deposit Expences',
        breadcrumb: [
          {
            text: 'Branch Finance',
            to: { name: 'branch-finance' },
          },
          {
            text: 'Deposit Expences',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/merchant-overview',
      name: 'merchant-overview',
      component: () => import('@/components/FinanceModuleForms/FinanceOverview/MerchatnOvewrview.vue'),
      meta: {
        pageTitle: 'Merchant',
        breadcrumb: [
          {
            text: 'Merchant Overview',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/overview/#0-toc-title',
      },
    },
    {
      path: '/operations/merchant-finance',
      name: 'merchant-finance',
      component: () => import('@/views/finance/MerchantFinance.vue'),
      meta: {
        pageTitle: 'Invoices',
        breadcrumb: [
          {
            text: 'Merchant',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/finance-invoices/',
      },
    },
    {
      path: '/operations/walking-finance',
      name: 'walking-finance',
      component: () => import('@/views/finance/MerchantFinance.vue'),
      meta: {
        pageTitle: 'Invoices',
        breadcrumb: [
          {
            text: 'Walking Customer',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/finance-invoices/',
      },
    },
    {
      path: '/operations/add-new-merchant-invoice',
      name: 'add-new-merchant-invoice',
      component: () => import('@/components/FinanceModuleForms/AddNewInvoice.vue'),
      meta: {
        pageTitle: 'Add New Invoice',
        breadcrumb: [
          {
            text: 'Merchant Finance',
            to: { name: 'merchant-finance' },
          },
          {
            text: 'Add New Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/merchant-orders/:id',
      name: 'merchant-orders',
      component: () => import('@/components/FinanceModuleForms/ActionMerchantForms/OrderList.vue'),
      meta: {
        pageTitle: 'Invoices',
        breadcrumb: [
          {
            text: 'Merchant',
            to: { name: 'merchant-finance' },
          },
          {
            text: 'Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/walking-orders/:id',
      name: 'walking-orders',
      component: () => import('@/components/FinanceModuleForms/ActionMerchantForms/OrderList.vue'),
      meta: {
        pageTitle: 'Invoices',
        breadcrumb: [
          {
            text: 'Walking Customer',
            to: { name: 'walking-finance' },
          },
          {
            text: 'Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operations/merchant-finance-invoice/:id',
      name: 'merchant-finance-invoice',
      component: () => import('@/components/FinanceModuleForms/ActionMerchantForms/merchantInvoicePreview.vue'),
      meta: {
        pageTitle: '',
        contentRenderer: 'pdf',
      },
    },
    {
      path: '/operations/walking-finance-invoice/:id',
      name: 'walking-finance-invoice',
      component: () => import('@/components/FinanceModuleForms/ActionMerchantForms/merchantInvoicePreview.vue'),
      meta: {
        pageTitle: '',
        contentRenderer: 'pdf',
      },
    },
    {
      path: '/reports/monthly-sales/',
      name: 'monthly-sales',
      component: () => import('@/views/reports/MonthlySales.vue'),
      meta: {
        pageTitle: 'Monthly Sales',
        breadcrumb: [
          {
            text: 'Monthly Sales',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/reports/#0-toc-title',
      },
    },
    {
      path: '/reports/branch-status-count/',
      name: 'branch-status-count',
      component: () => import('@/views/reports/BranchStatusCount.vue'),
      meta: {
        pageTitle: 'Branch Status Count',
        breadcrumb: [
          {
            text: 'Branch Status Count',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reports/branch-status-count/',
      name: 'agent-status-count',
      component: () => import('@/views/reports/AgentstatusCount.vue'),
      meta: {
        pageTitle: 'Agent Status Count',
        breadcrumb: [
          {
            text: 'Agent Status Count',
            active: true,
          },
        ],
      },
    },
    {
      path: '/administration/reasons',
      name: 'reason',
      component: () => import('@/views/reason/reason.vue'),
      meta: {
        pageTitle: 'Reasons',
        breadcrumb: [
          {
            text: 'Reasons',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/reasons/',
      },
    },
    {
      path: '/reports/warehouse-status-count/',
      name: 'warehouse-status-count',
      component: () => import('@/views/reports/WarehouseStatusCount.vue'),
      meta: {
        pageTitle: 'Warehouse Status Count',
        breadcrumb: [
          {
            text: 'Warehouse Status Count',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/reports/#1-toc-title',
      },
    },
    {
      path: '/reports/merchant-status-count/',
      name: 'merchant-status-count',
      component: () => import('@/views/reports/merchantStatusCount.vue'),
      meta: {
        pageTitle: 'Merchant Status Count',
        breadcrumb: [
          {
            text: 'Merchant Status Count',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/reports/#1-toc-title',
      },
    },
    {
      path: '/reports/assigned-orders-reports/',
      name: 'assigned-orders-reports',
      component: () => import('@/views/reports/AssignedOrdersReports.vue'),
      meta: {
        pageTitle: 'Assigned Orders',
        breadcrumb: [
          {
            text: 'Assigned Orders',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/reports/#1-toc-title',
      },
    },
    {
      path: '/report/returned-orders-to-merchant-report/',
      name: 'returned-orders-to-merchant-report',
      component: () => import('@/views/reports/ReturnOrderMerchant/ReturnOrderMerchant.vue'),
      meta: {
        pageTitle: 'Returned Orders to Merchant',
        breadcrumb: [
          {
            text: 'Assigned Orders',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/reports/#1-toc-title',
      },
    },
    {
      path: '/report/assigned-orders-to-warehouse-report/',
      name: 'assigned-orders-to-warehouse-report',
      component: () => import('@/views/reports/AssignOrderWarehouse/AssignOrderWarehouse.vue'),
      meta: {
        pageTitle: 'Assigned Orders to Destination Warehouse',
        breadcrumb: [
          {
            text: 'Assigned Orders',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/reports/#1-toc-title',
      },
    },
    {
      path: '/report/returned-orders-to-origin-warehouse-report/',
      name: 'returned-orders-to-origin-warehouse-report',
      component: () => import('@/views/reports/ReturnOrderOriginWarehouse/ReturnOrderOriginWarehouse.vue'),
      meta: {
        pageTitle: 'Returned Orders to Origin Warehouse',
        breadcrumb: [
          {
            text: 'Assigned Orders',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/reports/#1-toc-title',
      },
    },
    {
      path: '/reports/assign-orders-pickup-rider/',
      name: 'assign-orders-pickup-rider',
      component: () => import('@/views/reports/AssignOrdersPickupRider/AssignOrdersPickupRider.vue'),
      meta: {
        pageTitle: 'Assigned Orders to Pickup Rider',
        breadcrumb: [
          {
            text: 'Assigned Orders',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/reports/#1-toc-title',
      },
    },
    {
      path: '/reports/branch-expenses-report/',
      name: 'branch-expenses-report',
      component: () => import('@/views/reports/BranchExpenses/BranchExpenses.vue'),
      meta: {
        pageTitle: 'Branch Expenses Report',
        breadcrumb: [
          {
            text: 'Finance',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/reports/#1-toc-title',
      },
    },
    {
      path: '/reports/branch-progress-report/',
      name: 'branch-progress-report',
      component: () => import('@/views/reports/BranchProgress/BranchProgress.vue'),
      meta: {
        pageTitle: 'Branch Progress Report',
        breadcrumb: [
          {
            text: 'Finance',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/reports/#1-toc-title',
      },
    },
    {
      path: '/reports/to-be-invoiced-report/',
      name: 'to-be-invoiced-report',
      component: () => import('@/views/reports/ToBeInvoiced/ToBeInvoiced.vue'),
      meta: {
        pageTitle: 'To Be Invoiced Report',
        breadcrumb: [
          {
            text: 'Finance',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/reports/#1-toc-title',
      },
    },
    {
      path: '/chat/:chatroomId',
      name: 'chat-r2',
      component: () => import('@/views/chat/Chat.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'chat-application',
      },
    },
    {
      path: '/chat',
      name: 'chat',
      component: () => import('@/views/chat/Chat.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'chat-application',
        help: 'https://curfox.com/docs/chat/',
      },
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/views/Notifications.vue'),
      meta: {
        pageTitle: 'Notification',
        breadcrumb: [
          {
            text: 'Notification',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pickup-notifications',
      name: 'pickupNotifications',
      component: () => import('@/views/PickupNotifications.vue'),
      meta: {
        pageTitle: 'Notifications',
        breadcrumb: [
          {
            text: 'Pickup Requests',
            active: true,
          },
        ],
      },
    },
    {
      path: '/merchants',
      name: 'merchants',
      component: () => import('@/views/merchant/MerchantManagement.vue'),
      meta: {
        pageTitle: 'Merchants',
        breadcrumb: [
          {
            text: 'Merchants',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/user-management-customers/#0-toc-title',
      },
    },
    {
      path: '/walking-customer',
      name: 'walking-customer',
      component: () => import('@/views/walkingCustomer/WalkingCustomer.vue'),
      meta: {
        pageTitle: 'Walking Customer',
        breadcrumb: [
          {
            text: 'Walking Customer',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/user-management-customers/#1-toc-title',
      },
    },
    {
      path: '/merchant/:id',
      name: 'merchant-details-management',
      component: () => import('@/views/merchant/MerchantBusinessManagement.vue'),
      meta: {
        pageTitle: 'Merchant Business',
        breadcrumb: [
          {
            text: 'Merchants',
            to: { name: 'merchants' },
          },
          {
            text: 'Merchant Business',
            active: true,
          },
        ],
      },
    },
    {
      path: '/merchant/:id/businesses/:businessId',
      name: 'merchant-business-details',
      component: () => import('@/views/merchant/MerchantBusinessDetailsManagement.vue'),
      meta: {
        pageTitle: 'Merchant Business Details',
        breadcrumb: [
          {
            text: 'Merchants',
            to: { name: 'merchants' },
          },
          {
            text: 'Merchant Business',
            to: { name: 'merchant-details-management' },
          },
          {
            text: 'Merchant Business Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/system-settings/business-profile',
      name: 'business-profile',
      component: () => import('@/components/setting/BusinessProfile.vue'),
      meta: {
        pageTitle: 'Business Profile',
        breadcrumb: [
          {
            text: 'Business Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/system-settings/business-setting',
      name: 'business-setting',
      component: () => import('@/views/BusinessSetting.vue'),
      meta: {
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Genaral Settings',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/settings-2/',
      },
    },
    {
      path: '/system-settings/status-setting',
      name: 'status-setting',
      component: () => import('@/components/setting/OrderStatus.vue'),
      meta: {
        pageTitle: 'Status',
        breadcrumb: [
          {
            text: 'Status Settings',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/settings-2/',
      },
    },
    {
      path: '/operation-settings/cities',
      name: 'city',
      component: () => import('@/views/demarcation/CityManage.vue'),
      meta: {
        pageTitle: 'City',
        breadcrumb: [
          {
            text: 'City',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/demarcation/',
      },
    },
    {
      path: '/operation-settings/cities/city-create',
      name: 'city-create',
      component: () => import('@/views/demarcation/ExcelUploadCity/ExcelCityCreate.vue'),
      meta: {
        pageTitle: 'City Create',
        breadcrumb: [
          {
            text: 'Create',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/order-create/',
      },
    },
    {
      path: '/operation-settings/state/state-excel',
      name: 'state-create',
      component: () => import('@/views/demarcation/ExcelUpload.vue'),
      meta: {
        pageTitle: 'State',
        breadcrumb: [
          {
            text: 'state',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/demarcation/',
      },
    },
    {
      path: '/operation-settings/state',
      name: 'state',
      component: () => import('@/views/demarcation/StateManage.vue'),
      meta: {
        pageTitle: 'State',
        breadcrumb: [
          {
            text: 'state',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/demarcation/',
      },
    },
    {
      path: '/rate-card/add-rate-card/:rateCardType',
      name: 'add-rate-card',
      component: () => import('@/components/rate-card/rateCardForm.vue'),
      meta: {
        pageTitle: 'Add Rate Card',
        breadcrumb: [
          {
            text: 'Rate Card',
            active: true,
          },
        ],
        help: [
          {
            title: 'Tutorial 08 - Merchant Rate Card | Creating a new rate card Per kg',
            link: 'https://www.youtube.com/embed/FYPM7qPBi1k',
          },
          {
            title: 'Tutorial 09 - Merchant Rate Card | Creating a new rate card Per Order',
            link: 'https://www.youtube.com/embed/Z2QXTRfK4iU',
          },
          {
            title: 'Tutorial 10 - Merchant Rate Card | Assigning merchants to the rate card',
            link: 'https://www.youtube.com/embed/EJl7CLIIBKM',
          },
        ],
      },
    },
    {
      path: '/rate-card/view-rate-card/:rateCardType/:id',
      name: 'view-rate-card',
      component: () => import('@/components/rate-card/rateCardForm.vue'),
      meta: {
        pageTitle: 'View Rate Card',
        breadcrumb: [
          {
            text: 'Rate Card',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rate-card/clone-rate-card/:rateCardType/:id',
      name: 'clone-rate-card',
      component: () => import('@/components/rate-card/rateCardForm.vue'),
      meta: {
        pageTitle: 'Clone Rate Card',
        breadcrumb: [
          {
            text: 'Rate Card',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rate-card/update-rate-card/:rateCardType/:id',
      name: 'update-rate-card',
      component: () => import('@/components/rate-card/rateCardForm.vue'),
      meta: {
        pageTitle: 'Update Rate Card',
        breadcrumb: [
          {
            text: 'Rate Card',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rate-card/delete-rate-card/:rateCardType/:id',
      name: 'delete-rate-card',
      component: () => import('@/components/rate-card/rateCardForm.vue'),
      meta: {
        pageTitle: 'Delete Rate Card',
        breadcrumb: [
          {
            text: 'Rate Card',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rate-card/merchant',
      name: 'merchant-rate-card',
      component: () => import('@/views/rate-card/MerchantRateCard.vue'),
      meta: {
        pageTitle: 'Merchant Rate Card',
        breadcrumb: [
          {
            text: 'Merchant Rate Card',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/rate-cards/',
      },
    },
    {
      path: '/rate-card/agent',
      name: 'agent-rate-card',
      component: () => import('@/views/rate-card/AgentRateCard.vue'),
      meta: {
        pageTitle: 'Agent Rate Card',
        breadcrumb: [
          {
            text: 'Agent Rate Card',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/rate-cards/',
      },
    },
    {
      path: '/rate-card/rider',
      name: 'rider-rate-card',
      component: () => import('@/views/rate-card/RiderRateCard.vue'),
      meta: {
        pageTitle: 'Rider Rate Card',
        breadcrumb: [
          {
            text: 'Rider Rate Card',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/rate-cards/',
      },
    },
    {
      path: '/rate-card/walking-customer',
      name: 'walking-customer-rate-card',
      component: () => import('@/views/rate-card/WalkingCustomerRateCard.vue'),
      meta: {
        pageTitle: 'Walking Customer Rate Card',
        breadcrumb: [
          {
            text: 'Walking Customer Rate Card',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/rate-cards/#1-toc-title',
      },
    },
    {
      path: '/operation-settings/zone',
      name: 'zone',
      component: () => import('@/views/demarcation/ZoneManage.vue'),
      meta: {
        pageTitle: 'Zone',
        breadcrumb: [
          {
            text: 'Zone',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/demarcation/',
      },
    },
    {
      path: '/operation-settings/branch',
      name: 'branch',
      component: () => import('@/views/demarcation/BranchManage.vue'),
      meta: {
        pageTitle: 'Branch',
        breadcrumb: [
          {
            text: 'Branch',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/demarcation/',
      },
    },
    {
      path: '/operation-settings/agency',
      name: 'agency',
      component: () => import('@/views/demarcation/AgencyManage.vue'),
      meta: {
        pageTitle: 'Agency',
        breadcrumb: [
          {
            text: 'Agency',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/demarcation/',
      },
    },
    {
      path: '/operation-settings/reasons',
      name: 'reasons',
      component: () => import('@/views/Reasons.vue'),
      meta: {
        pageTitle: 'Reasons',
        breadcrumb: [
          {
            text: 'Reasons',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        noAuth: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/forgot-password/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        noAuth: true,
      },
    },
    {
      path: '/reset-password/:email/:token',
      name: 'reset-password',
      component: () => import('@/views/forgot-password/ResetPassword.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        noAuth: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/staff-manage/staff-users',
      name: 'staff-users',
      component: () => import('@/views/staff/StaffView.vue'),
      meta: {
        pageTitle: 'Staff Management',
        breadcrumb: [
          {
            text: 'View All Staff Users',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/user-management-staff/#0-toc-title',
      },
    },
    {
      path: '/quickbook',
      name: 'quickbook',
      component: () => import('@/views/quickBook/quickbook.vue'),
      meta: {
        pageTitle: 'QuickBooks',
        breadcrumb: [
          {
            text: 'QuickBook Integration',
            active: true,
          },
        ],
      },
    },
    {
      path: '/waybillrequest',
      name: 'waybillrequest',
      component: () => import('@/views/waybill-request/WaybillRequest.vue'),
      meta: {
        pageTitle: 'Waybill Request',
        breadcrumb: [
          {
            text: 'Waybill Request',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs-category/staff-portal/',
      },
    },
    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/roles/Roles.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Roles',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/user-management-staff/#1-toc-title',
      },
    },
    {
      path: '/roles/add',
      name: 'role-add',
      component: () => import('@/components/roles/RoleManagement.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Add',
            active: true,
          },
        ],
      },
    },
    {
      path: '/roles/edit/:roleId',
      name: 'role-edit',
      component: () => import('@/components/roles/RoleManagement.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/package-settings/my-subscription',
      name: 'my-subscription',
      component: () => import('@/views/Subscriptions.vue'),
      meta: {
        pageTitle: 'My Subscription',
        breadcrumb: [
          {
            text: 'My Subscription',
            active: true,
          },
        ],
      },
    },
    {
      path: '/package-settings/find-packages/:activePackageId',
      name: 'find-packages',
      component: () => import('@/components/SubscriptionPlan/Packages.vue'),
      meta: {
        pageTitle: 'Subscription Plans',
        breadcrumb: [
          {
            text: 'Subscription Plans',
            active: true,
          },
        ],
      },
    },
    {
      path: '/package-settings/subscription',
      name: 'subscription',
      component: () => import('@/views/adminportal/Subscription.vue'),
      meta: {
        pageTitle: 'My Subscription',
        breadcrumb: [
          {
            text: 'My Subscription',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/demarcation/',
      },
    },
    {
      path: '/package-settings/subscription-billing',
      name: 'billing',
      component: () => import('@/views/adminportal/Billing.vue'),
      meta: {
        pageTitle: 'My Billing',
        breadcrumb: [
          {
            text: 'My Billing',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/demarcation/',
      },
    },
    {
      path: '/package-settings/packages',
      name: 'package',
      component: () => import('@/views/adminportal/Package.vue'),
      meta: {
        pageTitle: 'My Packages',
        breadcrumb: [
          {
            text: 'My Packages',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/demarcation/',
      },
    },
    {
      path: '/package-settings/subscribe/:id',
      name: 'subscribe',
      component: () => import('@/components/SubscriptionPlan/Pay.vue'),
      meta: {
        pageTitle: 'Payment Options',
        breadcrumb: [
          {
            text: 'Pay',
            active: true,
          },
        ],
      },
    },
    {
      path: '/view-invoice/:id',
      name: 'view-invoice',
      component: () => import('@/components/subsInvoive/Invoice.vue'),
      meta: {
        pageTitle: '',
      },
    },
    {
      path: '/view-package/:id',
      name: 'view-package',
      component: () => import('@/views/packages/ViewPackage.vue'),
      meta: {
        pageTitle: 'View Package',
        breadcrumb: [
          {
            text: 'View Package',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tickets',
      name: 'tickets',
      component: () => import('@/views/tickets/Tickets.vue'),
      meta: {
        pageTitle: 'Tickets',
        breadcrumb: [
          {
            text: 'Tickets',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/support-feedback/#0-toc-title',
      },
    },
    {
      path: '/tickets/:id',
      name: 'ticket-details',
      component: () => import('@/views/tickets/TicketDetails.vue'),
      meta: {
        pageTitle: 'Tickets',
        breadcrumb: [
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: () => import('@/views/feedback/Feedback.vue'),
      meta: {
        pageTitle: 'Feedback',
        breadcrumb: [
          {
            text: 'All',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/support-feedback/#1-toc-title',
      },
    },
    {
      path: '/reports/all-feedbacks',
      name: 'all-feedbacks',
      component: () => import('@/views/feedback/AllFeedback.vue'),
      meta: {
        pageTitle: 'All Customer Feedbacks',
        breadcrumb: [
          {
            text: 'Customer Feedback',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/reports/',
      },
    },
    {
      path: '/rate-us/:code',
      name: 'customer-feedback',
      component: () => import('@/views/feedback/CustomerFeedback.vue'),
      meta: {
        layout: 'full',
        noAuth: true,
      },
    },
    // Resource Page Routes
    {
      path: '/resources',
      name: 'resources',
      component: () => import('@/views/resource/Resource.vue'),
      meta: {
        pageTitle: 'Resources',
        breadcrumb: [
          {
            text: 'Resources',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/settings-2/',
      },
    },
    {
      path: '/resources/view-mobile-app-resources',
      name: 'mobile-app',
      component: () => import('@/views/resource/MobileApp.vue'),
      meta: {
        pageTitle: 'Resources',
        breadcrumb: [
          {
            text: 'Resources',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/resources/',
      },
    },

    {
      path: '/resources/view-merchant-resource',
      name: 'merchant',
      component: () => import('@/views/resource/MerchantResources.vue'),
      meta: {
        pageTitle: 'Resources',
        breadcrumb: [
          {
            text: 'Resources',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/settings-2/',
      },
    },

    {
      path: '/resources/view-customer-resources',
      name: 'customer',
      component: () => import('@/views/resource/CustomerResources.vue'),
      meta: {
        pageTitle: 'Resources',
        breadcrumb: [
          {
            text: 'Resources',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/settings-2/',
      },
    },

    {
      path: '/resources/view-other-resources',
      name: 'other',
      component: () => import('@/views/resource/OtherResources.vue'),
      meta: {
        pageTitle: 'Resources',
        breadcrumb: [
          {
            text: 'Resources',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/settings-2/',
      },
    },
    {
      path: '/approvals/profile-update',
      name: 'profile-update-approval-request-list',
      component: () => import('@/views/approvals/ProfileUpdateApproval.vue'),
      meta: {
        pageTitle: 'Approvals',
        breadcrumb: [
          {
            text: 'Profile Update',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/settings-2/',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
