import Repository from '@/libs/axios'

const smsConfigResource = '/admin/settings/sms'

export default {
  getSMSConfigResource() {
    return Repository.get(`${smsConfigResource}`)
  },
  updateSMSConfigResource(payload) {
    return Repository.post(`${smsConfigResource}`, payload)
  },
  sendTestSMS(payload) {
    return Repository.post(`${smsConfigResource}/test-sms`, payload)
  },
}
