import Repository from '@/libs/axios'

const generalSearchResource = '/admin/general-search/'

export default {
  cityListByName(searchTerm) {
    return Repository.get(`${generalSearchResource}city-list-by-name?search_term=${searchTerm}`)
  },
  getOrdersByWarehouse(waybillNumber) {
    return Repository.get(`${generalSearchResource}waybill?waybill_number=${waybillNumber}`)
  },
}
